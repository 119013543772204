import {colorTheme} from '../../../../../core/configs';
import {StyledSubscriptionItem} from './styles';
import {localization} from '../../../../localization/Localization';
import { Typography } from '../../../../../core/components';
import { SubscriptionResponse } from '../../../../server/types/subscription.types';

interface SubscriptionItemProps {
  data?: SubscriptionResponse
  isSelected?: boolean
  onClick?: () => void
}

const SubscriptionItem = (props: SubscriptionItemProps) => {

  const getDescription = (): string => {
   
    let desc = `Php ${props.data?.amount}`

    if (props.data?.type == 'credit') {
      desc = `${desc} Unli chats up to <b>${props.data.creditLimit}</b> kasambahays`
    } else {
      let period = 'a lifetime'
      if (props?.data
        && props.data?.period) {
          period = `${props.data.period} ${props.data.period > 1 ? 'days' : 'day'} `
      }
  
      desc = `${desc} Valid for ${period}`
    }

    return desc
  }
  
  return (
    <StyledSubscriptionItem
      isSelected={props.isSelected}
      onClick={props.onClick}
    >
      <div className="flex flex-col space-y-1">
        <Typography
          label={props?.data?.name ?? ""}
          variant='f2'
          color={colorTheme.dark}/>
        <Typography
          label={getDescription()}
          variant='f1'
          color={colorTheme.darkFaded}/>
      </div>
    </StyledSubscriptionItem>
  );
};

export default SubscriptionItem;
