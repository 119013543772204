import Page from '../../../../core/components/page/Page';
import {Typography} from '../../../../core/components';
import {colorTheme} from '../../../../core/configs';
import {StyledImg} from '../styles';
import subscription from '../../../../assets/subscription.svg';
import SettingsItemViewProps from '../../../views/cards/settings-item/SettingsItemView';
import {RoutePath} from '../../../navigation/config/RouteConfig';
import {localization} from '../../../localization/Localization';
import { useGetActiveSubscription } from '../../../server/react-query/useUserSubscription';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import format from 'date-fns/format';
import { useGetActiveTrialSubscription } from '../../../server/react-query/useTrialSubscription';

const SubscriptionDetailsPage = () => {
  const {data: userSubscription} = useGetActiveSubscription(true)
  const {data: trialSubscription} = useGetActiveTrialSubscription()

  const getHeader = (): string => {
    let header = "You do not have any active subscription."

    if (userSubscription) {
      if(userSubscription.subscription?.type == 'credit') {
        return `Your active ${userSubscription?.subscriptionMeta?.name} subscription have 
          ${userSubscription.remainingCredits} remaining 
          ${(userSubscription.remainingCredits ?? 0) > 1 ? 'credits' : 'credit'}.`
      } else {
        var endDate = userSubscription?.expiresOn;
        if (!!endDate) {
          return `Your active ${userSubscription?.subscriptionMeta?.name} subscription will expire in 
            ${formatDistanceToNow(new Date(endDate))} on 
            ${format(new Date(endDate), 'PP')} at 
            ${format(new Date(endDate), 'p')}.`
        } else {
          return `Your active ${userSubscription?.subscriptionMeta?.name} subscription has no expiration.`
        }
      }
    }
    
    if (trialSubscription) {
      return `You're on a trial subscription with free ${trialSubscription.freeChats} ${trialSubscription.freeChats > 1 ? 'chats' : 'chat'}.`
    }

    return header
  }

  const getDesc = (): string => {
    let desc = "You will be asked to subscribe when you start a new chat."

    if (userSubscription) {
      if (userSubscription.subscription?.type == 'credit') {
        return "When all your credits are consumed, you will be asked to subscribe again the next time you start a new chat. However, your credit-paid chats will still be accessible."
      }
      
      return "When your subscription expires, you will be asked to subscribe again the next time you start a new chat."
    } 
    
    if (trialSubscription) 
      return `When you consumed the free ${trialSubscription.freeChats > 1 ? 'chats' : 'chat'}, you will be asked to subscribe the next time you start a new chat.`

    return desc
  }

  return (
    <Page showBackButton showDesktopBackButton>
      <div className="space-y-4">
        <Typography
          label={localization.hdr_subs_details}
          variant="f3"
          weight="semibold"
          align="center"
          color={colorTheme.dark}
        />

        <div>
          <StyledImg src={subscription} alt="settings"></StyledImg>
        </div>

        <div className="pt-6 space-y-4">
          <div>
            <Typography
              label={getHeader()}
              variant="f2"
              color={colorTheme.dark}
            />
            <Typography
              label={getDesc()}
              variant="f1"
              color={colorTheme.darkFaded}
            />
          </div>
          <SettingsItemViewProps
            label="Subscription History"
            description="See details of current and previous subscriptions."
            path={RoutePath.SUBSCRIPTION_HISTORY}
          />
        </div>
      </div>
    </Page>
  );
};

export default SubscriptionDetailsPage;
