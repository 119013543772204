import {useMutation, useQueryClient} from 'react-query';
import {API, ApiEndpointsEnum} from '../api';
import {QueryKeys} from './config/QueryConfig';
import {PostFilterSettingsBody, PutRoleSkills} from '../types/filter-settings.types';

const usePostFilterSettings = () => {
  const queryClient = useQueryClient();

  return useMutation(
    [QueryKeys.POST_FILTER_SETTINGS],
    (data: PostFilterSettingsBody) =>
      API.post(ApiEndpointsEnum.POST_FILTER_SETTINGS, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.POST_FILTER_SETTINGS]);
      },
    },
  );
};

const putRoleSkills = async (reqBody: PutRoleSkills) => {
  const url = ApiEndpointsEnum.PUT_ROLE_SKILLS;
  return API.put(url, reqBody);
};

const usePutRoleSkills = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ['put-role-skills'],
    (reqBody: PutRoleSkills) => putRoleSkills(reqBody),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['put-role-skills']);
        queryClient.fetchQuery(['get-my-profile']);
        queryClient.fetchQuery(['get-finder-setting']);
      },
    },
  );
};

export {
  usePostFilterSettings,
  usePutRoleSkills
};
