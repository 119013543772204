import styled, {css} from 'styled-components';
import {
  TypographyAlignment,
  TypographyVariant,
  TypographyScale,
} from './typography.types';
import { colorTheme } from '../../configs';

const variantScales: {[key in TypographyVariant]: TypographyScale} = {
  f6: {
    fontSize: 2.441,
    lineHeight: 3.662,
    letterSpacing: 0,
  },
  f5: {
    fontSize: 1.953,
    lineHeight: 2.93,
    letterSpacing: 0,
  },
  f4: {
    fontSize: 1.563,
    lineHeight: 2.344,
    letterSpacing: 0.01875,
  },
  f3: {
    fontSize: 1.25,
    lineHeight: 1.875,
    letterSpacing: 0,
  },
  f2: {
    fontSize: 1,
    lineHeight: 1.5,
    letterSpacing: 0,
  },
  f1: {
    fontSize: 0.8,
    lineHeight: 1.2,
    letterSpacing: 0,
  },
  caption: {
    fontSize: 0.643,
    lineHeight: 0.964,
    letterSpacing: 0,
  },
};

interface StyledTypographyProps {
  variant: TypographyVariant;
  weight: number;
  align: TypographyAlignment;
  color: string;
  singleLine?: boolean;
}

export const StyledTypography = styled.div<StyledTypographyProps>`
  font-size: ${pr => variantScales[pr.variant].fontSize}rem;
  line-height: ${pr => variantScales[pr.variant].lineHeight}rem;
  letter-spacing: ${pr => variantScales[pr.variant].letterSpacing}rem;
  font-weight: ${pr => pr.weight};
  text-align: ${pr => pr.align};
  color: ${pr => pr.color};
  ${pr => pr.singleLine && css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}

  a, b {
    color: ${colorTheme.primary}
  }

  span.bold {
    font-weight: 700;
  }
`;
