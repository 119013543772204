import Page from '../../../core/components/page/Page';
import {Checkbox, Input, Typography} from '../../../core/components';
import {colorTheme} from '../../../core/configs';
import {useHistory} from 'react-router-dom';
import {
  getProvinces,
  getRoles,
  useGetAdminControl,
  useGetFinderSetting,
  usePostFilterSettings,
} from '../../server/react-query';
import {useEffect, useState} from 'react';
import {
  FilterSettingResponse,
  KazamException,
  PostFilterSettings,
  ProvinceResponse,
  Role,
} from '../../server/types';
import {Controller, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {PostFilterSettingsBody} from '../../server/types/filter-settings.types';
import {RoutePath} from '../../navigation/config/RouteConfig';
import {useAuth} from '../../hooks/useAuth';
import EmploymentComponent from './EmploymentComponent';
import {postFilterSettingSchema} from '../../schema/filter-setting.schema';
import SearchAreaComponent from './SearchAreaComponent';
import {useQuery} from 'react-query';
import {localization} from '../../localization/Localization';
import {isMobile} from 'react-device-detect';
import {StyledInputLabel} from './styled';
import { useToast } from '../../../core/components/toast';

interface RoleState {
  roleId: string;
  text: string;
  value: number;
  state: boolean;
}

const DefaultFinderSettingsPage = () => {
  const history = useHistory();
  const {presentToast} = useToast();
  
  const {updateAuthData, authData} = useAuth();
  const [roles, setRoles] = useState<RoleState[]>([]);
  const [provinces, setProvinces] = useState<ProvinceResponse[]>([]);
  const [showRoleError, setShowRoleError] = useState(false);
  const [fetchRoles, setFetchRoles] = useState(false);
  const [fetchProvinces, setFetchProvinces] = useState(false);
  const [nearbyError, setNearbyError] = useState('');
  const [provinceError, setProvinceError] = useState('');
  const [homeownerLocation, setHomeownerLocation] = useState('');

  const {data: enableLgbtqia} = useGetAdminControl('enableLgbtqia');

  const {data: finderSettingData, isFetching} = useGetFinderSetting();
  const postFilterSettings = usePostFilterSettings();

  const {data: rolesData, isFetching: isRolesFetching} = useQuery(
    ['get-roles'],
    getRoles,
    {
      enabled: fetchRoles,
    },
  );

  const {data: provincesData, isFetching: isProvinceFetching} = useQuery(
    ['get-provinces'],
    getProvinces,
    {
      enabled: fetchProvinces,
    },
  );

  const {
    control,
    getValues,
    setValue,
    formState: {errors},
    setError,
    handleSubmit,
  } = useForm<PostFilterSettings>({
    resolver: yupResolver(postFilterSettingSchema),
    defaultValues: {
      maxSalary: 0,
      location: '',
      livingArrangement: 'stayIn',
      searchAreaType: 'allMatches',
      province: '',
      nearbyRadius: 0,
      lgbtqia: true,
    },
  });

  useEffect(() => {
    if (finderSettingData && finderSettingData.location) {
      setValue('location', finderSettingData.location.locationId);
      setHomeownerLocation(finderSettingData.location.cityOrMunicipality);
    }
  }, [finderSettingData, setValue]);

  useEffect(() => {
    var homeownerLocation = localStorage.getItem('homeownerLocation');
    if (homeownerLocation) {
      setValue('location', homeownerLocation);
    }

    var homeownerLocationName = localStorage.getItem('homeownerLocationName');
    if (homeownerLocationName) {
      setHomeownerLocation(homeownerLocationName);
    }

    var provinces = localStorage.getItem('provinces');
    if (provinces) {
      var provincesData = JSON.parse(provinces) as ProvinceResponse[];
      setProvinces(provincesData);
    } else {
      setFetchProvinces(true);
    }

    var roles = localStorage.getItem('roles');
    if (roles) {
      var rolesData = JSON.parse(roles) as Role[];
      setRolesData(rolesData);
    } else {
      setFetchRoles(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (provincesData) {
      setProvinces(provincesData);
      localStorage.setItem('provinces', JSON.stringify(provincesData));
    }
  }, [provincesData]);

  useEffect(() => {
    const roles = rolesData as Role[];
    setRolesData(roles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rolesData]);

  const setRolesData = (roles: Role[]) => {
    if (roles && roles.length > 0) {
      const roleState = roles?.map((role, i) => {
        return {
          roleId: role.roleId,
          text: role.text,
          state: false,
          value: i,
        };
      });
      setRoles(roleState);
      localStorage.setItem('roles', JSON.stringify(roles));
    }
  };
  const updateRole = (data: any) => {
    if (roles.length > 0) {
      let items = [...roles];
      let item = items[data.role.value];
      item.state = data.state;
      items[data.role.value] = item;
      setRoles(items);
    }
  };

  const handleSearchAreaError = (): boolean => {
    const data = getValues();

    switch (data.searchAreaType) {
      case 'inYourCity':
        return true;
      case 'allMatches':
        return true;
      case 'nearby':
        var error = data.nearbyRadius ? '' : 'This field is required.';
        setNearbyError(error);
        return error ? false : true;
      case 'province':
        var error = data.province ? '' : 'This field is required.';
        setProvinceError(error);
        return error ? false : true;
      default:
        return true;
    }
  };

  const submitForm = async () => {
    handleRoleEror();

    handleSearchAreaError() && handleSubmit(handleSave)();
  };

  const handleSave = async () => {
    try {
      var data = getValues();

      var selectedRoles = roles.filter(
        (role: {state: boolean}) => role.state === true,
      );

      if (selectedRoles.length > 0 && selectedRoles.length <= 3) {
        setShowRoleError(false);

        const values: PostFilterSettingsBody = {
          salary: data.maxSalary,
          searchAreaType: data.searchAreaType,
          livingArrangement: data.livingArrangement,
          location: data.location,
          roles: selectedRoles.map(role => {
            return role.roleId;
          }),
          province: data.province ? data.province : undefined,
          nearbyRadius: data.nearbyRadius ? data.nearbyRadius : undefined,
          lgbtqia: data.lgbtqia ?? true
        };

        var filterSettings = await postFilterSettings.mutateAsync(values);

        let authenticationData = authData;
        if (authenticationData && authenticationData.user) {
          authenticationData.user.filterSetting = filterSettings as FilterSettingResponse;
          updateAuthData(authenticationData);
        }

        localStorage.setItem('isNewHomeowner', 'true');
        // history.replace(RoutePath.MATCHES_LANDING);
        const path = RoutePath.TAKE_PERSONALITY_TEST + '?sign-up=true'
        history.replace(path);
      } else {
        setShowRoleError(true);
      }
    } catch (e: any) {
      const exception = e.data as KazamException;

      if (exception) {
        if (e.status
          && e.status === 403
          && exception.errorCode === 'SalaryLimitError') {
          setError("maxSalary", {message: exception.error});
        }

        presentToast({
          message: exception.error,
          variant: 'error',
          position: 'bottom',
        });
      } else {
        presentToast({
          message: "Failed to save.",
          variant: 'error',
          position: 'bottom',
        });
      }
    }
  };

  const handleRoleEror = () => {
    var selectedRoles = roles.filter(
      (role: {state: boolean}) => role.state === true,
    );

    if (selectedRoles.length === 0 || selectedRoles.length > 3) {
      setShowRoleError(true);
    } else {
      setShowRoleError(false);
    }
  };

  const onSelectProvince = (provinceId: string) => {
    setValue('province', provinceId);
  };

  const onNearbyKmChange = (radius: number) => {
    setValue('nearbyRadius', radius);
  };

  const onSalaryChange = (value: any) => {
    var data = value.currentTarget.value.replace(/,/g, '');
    setValue('maxSalary', parseFloat(data));
  };

  return (
    <Page
      showLoading={isRolesFetching || isProvinceFetching}
      showRightText={isMobile}
      showDesktopSaveButton={!isMobile}
      rightText="Save"
      onRightActionClick={submitForm}
      rightActionIsLoading={postFilterSettings.isLoading}>
      <div className="space-y-4">
        <div>
          <Typography
            label={localization.desc_search_settings}
            variant="f3"
            align="center"
            color={colorTheme.dark}></Typography>
        </div>
        <div className="space-y-4">
          <Typography
            label={localization.lbl_role_max}
            variant="f1"
            weight="semibold"
            align="left"
            color={showRoleError ? colorTheme.danger : colorTheme.darkFaded}
          />
          {roles?.map((role, i) => {
            return (
              <Checkbox
                key={i}
                label={role.text}
                checked={false}
                onChange={state => updateRole({state, role})}
              />
            );
          })}
        </div>
        <div className="pt-2">
          <Controller
            control={control}
            name="livingArrangement"
            render={({field: {value, onChange}}) => (
              <EmploymentComponent value={value} onChange={onChange} />
            )}
          />
        </div>
        <div className="pt-2">
          <div className="space-y-1 pb-2">
            <StyledInputLabel data-testid="label-element">
              {localization.lbl_max_salary}
            </StyledInputLabel>
            {/* <Typography
              label={localization.desc_salary_homeo}
              variant="f1"
              align="left"
              color={colorTheme.danger}
            /> */}
          </div>

          <Controller
            control={control}
            name="maxSalary"
            render={({field: {value = '', onBlur, onChange}}) => (
              <Input
                type="number"
                inputSize="large"
                placeholder={localization.ph_enter_max_salary}
                value={
                  typeof value === 'number'
                    ? value
                    : isNaN(parseFloat(value as string))
                    ? ''
                    : parseFloat(value as string)
                }
                onBlur={onBlur}
                onChange={onSalaryChange}
                error={errors.maxSalary?.message}
                showThousandSeparator
              />
            )}
          />
        </div>

        <div className="section">
          <Controller
            control={control}
            name="searchAreaType"
            render={({field: {value, onChange}}) => (
              <SearchAreaComponent
                provincesData={provinces}
                value={value}
                onChange={onChange}
                onSelectProvince={onSelectProvince}
                onNearbyChange={onNearbyKmChange}
                provinceError={provinceError}
                nearbyError={nearbyError}
                currentLocationName={homeownerLocation}
              />
            )}
          />
        </div>
        {enableLgbtqia && enableLgbtqia.enabled &&       
          <div className='flex'>
            <Controller
              control={control}
              name="lgbtqia"
              render={({field: {value, onBlur, onChange}}) => (
                <div className="flex">
                  <Checkbox
                    checked={finderSettingData?.lgbtqia ?? true}
                    onChange={onChange}
                    color={'default'}
                  />
                  <div className="flex space-x-1">
                    <Typography
                      label={localization.check_include_lgbtqia}
                      variant="f2"
                      align="left"
                      color={colorTheme.darkFaded}></Typography>
                  </div>
                </div>
              )}
            />
          </div>
        }
      </div>
    </Page>
  );
};

export default DefaultFinderSettingsPage;
