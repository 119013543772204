import { Typography } from "../../../../core/components";
import Pill from "../../../../core/components/pill";
import { colorTheme } from "../../../../core/configs";
import { UserResponse } from "../../../server/types";
import { StyledSection } from "./styles";
import Placeholder from '../../../../assets/placeholder.png';

export interface PlaceholderSectionProps {
  label?: string
  numberOfItems?: number
  asset?: string
}

const PlaceholderSection = ({
  label,
  numberOfItems = 1,
  asset
}: PlaceholderSectionProps) => {
  return (
    <StyledSection className="placeholder">
      {label &&
        <Typography
          className='label'
          label={label}
          variant="f1"
          weight="semibold"
          color={colorTheme.darkFaded}
        />
      }
      <div className="flex flex-col gap-2">
        {[...Array(numberOfItems)].map((_, i) => (
          <img
            key={i}
            src={asset ?? Placeholder}
            alt="placeholder"
          />
        ))}
      </div>
     
    </StyledSection>
  );
};

export default PlaceholderSection;
