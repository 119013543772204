import styled, {keyframes} from 'styled-components';
import {
  ButtonColor,
  ButtonSize,
  ButtonVariant,
  ButtonSizeStyle,
  VariantColor,
} from './button.types';

import {colorTheme} from './../../configs/color.config';

const buttonSizeStyles: {[key in ButtonSize]: ButtonSizeStyle} = {
  default: {
    height: 40,
    padding: 1.143,
    iconOnlyPadding: 0.5,
    textSize: 0.875,
  },
  large: {
    height: 48,
    padding: 1,
    iconOnlyPadding: 0.75,
    textSize: 0.875,
  },
  small: {
    height: 24,
    padding: 0.5,
    iconOnlyPadding: 0.375,
    textSize: 0.75,
  },
};

export const variantColors: {[key in ButtonColor]: VariantColor} = {
  default: {
    default: colorTheme.light,
    outlineBorder: colorTheme.lightGray,
    hover: 'none',
    contrast: colorTheme.dark,
    outlineHoverContrast: 'none',
  },
  primary: {
    default: colorTheme.primary,
    outlineBorder: colorTheme.primary,
    hover: 'none',
    contrast: colorTheme.white,
    outlineHoverContrast: 'none',
  },
  secondary: {
    default: colorTheme.secondary,
    outlineBorder: colorTheme.secondary,
    hover: 'none',
    contrast: colorTheme.white,
    outlineHoverContrast: 'none',
  },
  success: {
    default: colorTheme.primary,
    outlineBorder: colorTheme.primary,
    hover: 'none',
    contrast: colorTheme.white,
    outlineHoverContrast: 'none',
  },
  warning: {
    default: colorTheme.warning,
    outlineBorder: colorTheme.warning,
    hover: colorTheme.accent,
    contrast: colorTheme.white,
    outlineHoverContrast: colorTheme.white,
  },
  danger: {
    default: colorTheme.danger,
    outlineBorder: colorTheme.danger,
    hover: 'none',
    contrast: colorTheme.white,
    outlineHoverContrast: 'none',
  },
  darkFaded: {
    default: colorTheme.darkFaded,
    outlineBorder: colorTheme.darkFaded,
    hover: 'none',
    contrast: colorTheme.white,
    outlineHoverContrast: 'none',
  },
  light: {
    default: colorTheme.light,
    outlineBorder: colorTheme.light,
    hover: 'none',
    contrast: colorTheme.dark,
    outlineHoverContrast: 'none',
  },
  green: {
    default: colorTheme.green,
    outlineBorder: colorTheme.green,
    hover: 'none',
    contrast: colorTheme.white,
    outlineHoverContrast: 'none',
  },
  magenta: {
    default: colorTheme.magenta,
    outlineBorder: colorTheme.magenta,
    hover: 'none',
    contrast: colorTheme.white,
    outlineHoverContrast: 'none',
  },
};

interface StyledButtonProps {
  variant: ButtonVariant;
  size: ButtonSize;
  color: ButtonColor;
  disabled: boolean;
  isLoading: boolean;
  iconOnly: boolean;
  borderWidth: string;
  borderColor: ButtonColor;
}

export const StyledButton = styled.button<StyledButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: ${pr => (pr.disabled ? 'not-allowed' : 'pointer')};

  border-radius: 4px;

  height: ${pr => buttonSizeStyles[pr.size].height}px;

  width: 100%;

  padding-left: ${pr =>
    pr.iconOnly
      ? buttonSizeStyles[pr.size].iconOnlyPadding
      : buttonSizeStyles[pr.size].padding}rem;
  padding-right: ${pr =>
    pr.iconOnly
      ? buttonSizeStyles[pr.size].iconOnlyPadding
      : buttonSizeStyles[pr.size].padding}rem;

  font-size: ${pr => buttonSizeStyles[pr.size].textSize}rem;
  font-weight: 600;

  color: ${pr =>
    pr.variant === 'contained'
      ? variantColors[pr.color].contrast
      : pr.variant === 'border'
      ? variantColors[pr.color].contrast
      : pr.color === 'default'
      ? variantColors.default.contrast
      : variantColors[pr.color].default};

  border: ${pr =>
    pr.variant === 'outlined'
      ? `1px solid ${variantColors[pr.color].outlineBorder}`
      : pr.variant === 'border'
      ? `${pr.borderWidth} solid ${variantColors[pr.borderColor].outlineBorder}`
      : 'none'};

  background-color: ${pr =>
    pr.variant === 'contained'
      ? variantColors[pr.color].default
      :pr.variant === 'border'
      ? variantColors[pr.color].default
      : 'transparent'};

  &:hover {
    background-color: ${pr =>
      pr.variant === 'clear' ? 'transparent' : variantColors[pr.color].hover};

    color: ${pr =>
      pr.variant === 'outlined' &&
      variantColors[pr.color].outlineHoverContrast};
  }

  opacity: ${pr => pr.disabled && '0.7'};
`;

interface LoaderSize {
  size: number;
  thickness: number;
}

const loaderSizes: {[key in ButtonSize]: LoaderSize} = {
  default: {
    size: 20,
    thickness: 3,
  },
  small: {
    size: 14,
    thickness: 2,
  },
  large: {
    size: 32,
    thickness: 4,
  },
};

const animateSpin = keyframes`
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(360deg)
  }
`;

export const StyledLoader = styled.div<StyledButtonProps>`
  border-radius: 50%;
  border: ${pr => loaderSizes[pr.size].thickness}px solid
    ${pr =>
      pr.variant === 'contained'
        ? pr.color === 'default'
          ? ''
          : colorTheme.white
        : ''};
  border-top: ${pr => loaderSizes[pr.size].thickness}px solid transparent;
  width: ${pr => loaderSizes[pr.size].size}px;
  height: ${pr => loaderSizes[pr.size].size}px;

  -webkit-animation: ${animateSpin} 0.7s linear infinite; /* Safari */
  animation: ${animateSpin} 0.7s linear infinite;
`;
