import {colorTheme} from '../../configs';
import Typography from '../typography';
import {StyledDiv} from './styled';

export interface PillProps {
  value: string
}

const Pill = (props: PillProps) => {

  return (
    <StyledDiv>
      <Typography
        label={props.value}
        variant="f1"
        color={colorTheme.accent}
      />
    </StyledDiv>
  );
};

export default Pill;
