import {useForm, Controller} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {
  Typography,
  Input,
  Button,
  WizardStep,
  Typehead,
} from '../../../../core/components';
import {colorTheme} from '../../../../core/configs';
import {signUpJobSeekerInformationSchema} from '../../../schema/sign-up.schema';
import {
  FilterSettingResponse,
  KazamInformation,
  LocationResponse,
} from '../../../server/types/kazam.types';
import logo from '../../../../assets/KAZAM_logo_Transparent.svg';
import {useHistory} from 'react-router-dom';
import {RoutePath} from '../../../navigation/config/RouteConfig';
import {
  LivingArrangement,
  PostFilterSettingsBody,
} from '../../../server/types/filter-settings.types';
import {
  useGetLocations,
  usePostFilterSettings,
} from '../../../server/react-query';
import {useEffect, useState} from 'react';
import {useAuth} from '../../../hooks/useAuth';
import Page from '../../../../core/components/page/Page';
import {Nullable} from '../../../types/types';
import EmploymentComponent from '../../finder-settings/EmploymentComponent';
import {localization} from '../../../localization/Localization';
import {useAnalyticsTrackComponent} from '../../../hooks/useAnalyticsTrackComponent';
import {useAnalytics} from '../../../hooks/useAnalytics';
import { KazamException } from '../../../server/types';
import { useToast } from '../../../../core/components/toast';
import { STORAGE } from '../../../utils/storage.utils';

const SignUpJobSeekerInformationPage = (props: any) => {
  useAnalyticsTrackComponent(SignUpJobSeekerInformationPage.name);

  const history = useHistory();
  const {updateAuthData, authData} = useAuth();
  const {systemLogEvent} = useAnalytics();
  const {presentToast} = useToast();

  const {data} = useGetLocations();
  const postFilterSettings = usePostFilterSettings();

  const [locations, setLocations] = useState<LocationResponse[]>([]);
  const [livingArrangement, setLivingArrangement] = useState<LivingArrangement>('stayIn');
  const [selectedLocation, setSelectedLocation] = useState<LocationResponse>();

  const {
    control,
    getValues,
    setValue,
    formState: {errors},
    handleSubmit,
    setError,
    reset,
  } = useForm<KazamInformation>({
    resolver: yupResolver(signUpJobSeekerInformationSchema),
    defaultValues: {
      minSalary: 0,
      location: '',
      livingArrangement: 'stayIn',
    },
  });

  useEffect(() => {
    if (data) {
      setLocations(data);

      const signUpData = STORAGE.get<KazamInformation>('signUpData', true)
      if (signUpData) {
        reset(signUpData)
      }

      if (signUpData?.location) {
        var sel = data?.find(l => l.locationId === signUpData?.location)
        if (!!sel) setSelectedLocation(sel)
      }

      const selected = STORAGE.get<LocationResponse>('currentLocation', true)
      if (!!selected) setSelectedLocation(selected)

      STORAGE.clean('signUpCurrentStep')
      STORAGE.clean('signUpData')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const submitForm = async () => {
    handleSubmit(handleNext)();
  };

  const selectedEmploymentHandler = (value: LivingArrangement) => {
    setValue('livingArrangement', value);
  };

  const handleNext = async () => {
    try {
      var data = getValues();

      var signUp = localStorage.getItem('signup');
      if (signUp) {
        var signUpData = JSON.parse(signUp);

        const values: PostFilterSettingsBody = {
          salary: data.minSalary,
          livingArrangement: data.livingArrangement,
          location: data.location,
          roles: signUpData.roles,
          searchAreaType: 'inYourCity',
          lgbtqia: signUpData?.personalData?.lgbtqia ?? false
        };
        var filterSettings = await postFilterSettings.mutateAsync(values);
        let authenticationData = authData;
        if (authenticationData && authenticationData.user) {
          authenticationData.user.filterSetting = filterSettings as FilterSettingResponse;
          updateAuthData(authenticationData);
        }
      }

      systemLogEvent('kazam_reg_step_5');
      STORAGE.clean('currentLocation')
      props.nextStep();
    } catch (e: any) {
      console.log('error', e);
      const exception = e.data as KazamException;

      if (exception) {
        if (e.status
          && e.status === 403
          && exception.errorCode === 'SalaryLimitError') {
          setError("minSalary", {message: exception.error});
        }
        presentToast({
          message: exception.error,
          variant: 'error',
          position: 'bottom',
        });
      } else {
        presentToast({
          message: "Failed to save.",
          variant: 'error',
          position: 'bottom',
        });
      }
    }
  };

  const cancelSignUp = () => {
    localStorage.removeItem('signup');
    STORAGE.clean('signUpCurrentStep')
    STORAGE.clean('signUpData')
    STORAGE.clean('currentLocation')
    history.replace(RoutePath.LANDING);
  };

  const onSalaryChange = (value: any) => {
    var data = value.currentTarget.value.replace(/,/g, '');
    setValue('minSalary', parseFloat(data));
  };

  const goToCurrentLocation = () => {
    let data = getValues()
    if (selectedLocation?.locationId !== data.location) {
      STORAGE.clean('currentLocation')
    }

    STORAGE.save('signUpData', data, true)
    STORAGE.save('signUpCurrentStep', props.currentStep)
    history.push(RoutePath.SELECT_CURRENT_LOCATION);
  }

  return (
    <Page hideNavbar>
      <div className="space-y-6">
        <div>
          <WizardStep
            steps={props.totalSteps}
            currentStep={props.currentStep}></WizardStep>
        </div>
        <div className="flex justify-center">
          <img style={{height: '29px'}} src={logo} alt="Logo" />
        </div>
        <div>
          <div>
            <Typography
              label={localization.hdr_kzm_info}
              variant="f3"
              align="center"
              color={colorTheme.dark}></Typography>
          </div>
          <div className="pt-1">
            <Typography
              label={localization.desc_kzm_info}
              variant="f1"
              align="center"
              color={colorTheme.darkFaded}></Typography>
          </div>
        </div>
        <div>
          <div>
            <Typography
              label={localization.hdr_salary}
              variant="f2"
              align="left"
              color={colorTheme.dark}></Typography>
          </div>
          <div className="pt-1">
            {/* <Typography
              label={localization.desc_salary}
              variant="f1"
              align="left"
              color={colorTheme.danger}></Typography> */}
          </div>
          <div className="pt-2">
            <Controller
              control={control}
              name="minSalary"
              render={({field: {value = '', onBlur, onChange}}) => (
                <Input
                  type="number"
                  inputSize="large"
                  placeholder={localization.ph_enter_salary}
                  value={value}
                  onBlur={onBlur}
                  onChange={onSalaryChange}
                  error={errors.minSalary?.message}
                  showThousandSeparator
                />
              )}
            />
          </div>
        </div>

        <div>
          <div>
            <Typography
              label={localization.hdr_location}
              variant="f2"
              align="left"
              color={colorTheme.dark}></Typography>
          </div>
          <div className="pt-2">
            <Controller
              control={control}
              name="location"
              render={({field: {value, onChange}}) => (
                <Typehead
                  items={locations}
                  placeholder={localization.ph_search_loc}
                  onChange={sel => {
                    var item = sel && sel.length > 0 ? sel[0].locationId : '';
                    onChange(item);
                  }}
                  selected={selectedLocation}
                  error={errors.location?.message}></Typehead>
              )}
            />

            <div onClick={goToCurrentLocation}>
              <Typography
                className="cursor-pointer"
                label="Use current location"
                variant="f1"
                weight="normal"
                color={colorTheme.primary}
              />
            </div>
          </div>
        </div>

        <div>
          <div>
            <Typography
              label={localization.hdr_living_arr}
              variant="f2"
              align="left"
              color={colorTheme.dark}></Typography>
          </div>
          <div>
            <EmploymentComponent
              value={livingArrangement}
              onChange={selectedEmploymentHandler}
            />
          </div>
        </div>
        <div>
          <div>
            <Button
              label={localization.btn_next}
              color="primary"
              onClick={submitForm}
              isLoading={postFilterSettings.isLoading}
            />
          </div>
          <div className="pt-4">
            <Button
              label={localization.btn_cancel}
              variant="outlined"
              onClick={cancelSignUp}
            />
          </div>
        </div>
      </div>
    </Page>
  );
};

export default SignUpJobSeekerInformationPage;
