import { Typography } from "../../../../core/components";
import Pill from "../../../../core/components/pill";
import { colorTheme } from "../../../../core/configs";
import { UserResponse } from "../../../server/types";
import PlaceholderSection from "./PlaceholderSection";
import { StyledSection } from "./styles";
import Placeholder from '../../../../assets/personalities-placeholder.png';


export interface PersonalitiesSectionProps {
  profile?: UserResponse
  showExtendedProfile?: boolean
}

const PersonalitiesSection = ({
  profile,
  showExtendedProfile
}: PersonalitiesSectionProps) => {

  const personalities = profile?.filterSetting?.personalities ?? [];

  if (personalities.length == 0) return <></>;

  if (!showExtendedProfile) return <PlaceholderSection label="Personal Characteristics" asset={Placeholder}/>;

  return (
    <StyledSection >
      <Typography
        className='label'
        label="About Me"
        variant="f1"
        weight="semibold"
        color={colorTheme.darkFaded}
      />
      
      <div className='bordered flex flex-wrap gap-2'>
        {personalities.map((p,i) => {
          return (
            <Pill
              key={i}
              value={p}
            />
          )
        })}
      </div>
    </StyledSection>
  );
};

export default PersonalitiesSection;
