import {
  useQuery,
} from 'react-query';
import {API, ApiEndpointsEnum} from '../api';

const getCountries = () => {
  return API.get<string[]>(ApiEndpointsEnum.GET_COUNTRIES);
};

const useGetCountries = () => {
  return useQuery(['get-countries'], () => getCountries());
};

const getPersonalities = () => {
  return API.get<string[]>(ApiEndpointsEnum.GET_PERSONALITIES);
};

const useGetPersonalities = () => {
  return useQuery(['get-personalities'], () => getPersonalities());
};

export {
  useGetCountries,
  useGetPersonalities
};
