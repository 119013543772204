import * as React from 'react';
import {SVGProps, Ref, forwardRef, memo} from 'react';
const SvgArrowDown = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 25"
    ref={ref}
    {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12 5.5v14M19 12.5l-7 7-7-7"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgArrowDown);
const Memo = memo(ForwardRef);
export default Memo;
