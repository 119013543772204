import React, {useEffect, useReducer, useState} from 'react';
import {Column} from 'react-table';
import {
  useGetAbuseReports,
  useGetSuspendedUsers,
  useGetSystemStatistics,
  usePostBanUser,
  usePostSuspendUser,
} from '../../../../server/react-query';
import AdminTable from '../AdminTable';
import {StyledAdminReportUsersTable} from '../styled';
import {Icon, Typography} from '../../../../../core/components';
import {colorTheme} from '../../../../../core/configs';
import {useToast} from '../../../../../core/components/toast';
import {useAnalytics} from '../../../../hooks/useAnalytics';
import {useAuth} from '../../../../hooks/useAuth';
import {RoutePath} from "../../../../navigation/config/RouteConfig";
import {useHistory} from "react-router-dom";
import { reducer, INITIAL_SEARCH_STATE } from '../verified/AdminVerifiedUsersTable';

interface TableData {
  userId: string;
  name: string;
  location: string;
  numberOfReports: number;
  userType: string;
  isBanned: boolean;
}

interface AdminReportedUsersTableProps {
  searchQuery: string;
}

const AdminReportedUsersTable = (props: AdminReportedUsersTableProps) => {
  const {searchQuery: propSearchQuery} = props;

  const [pageCount, setPageCount] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [data, setData] = useState<TableData[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>(propSearchQuery);
  const [searchBody, dispatchSearch] = useReducer(reducer, INITIAL_SEARCH_STATE)
  const {
    data: hookDataAbuseReports,
    refetch: refetchGetAbuseReports,
  } = useGetAbuseReports(searchBody);

  const {
    data: hookDataSuspendedUsers,
    refetch: refetchGetSuspendedUsers,
  } = useGetSuspendedUsers(searchBody);

  const postBanUser = usePostBanUser();
  const postSuspendUser = usePostSuspendUser();
  const {
    data: hookDataSystemStatistics,
    refetch: refetchSystemStatistics,
  } = useGetSystemStatistics();
  const {systemLogEvent} = useAnalytics();
  const {authData} = useAuth();
  const history = useHistory();

  const {presentToast} = useToast();

  const [columns] = useState<Column<TableData>[]>([
    {
      Header: 'Name',
      accessor: 'name', // accessor is the "key" in the data
    },
    {
      Header: 'User ID',
      accessor: 'userId', // accessor is the "key" in the data
    },
    {
      Header: 'Location',
      accessor: 'location',
    },
    {
      Header: 'No. of Reports',
      accessor: 'numberOfReports',
    },
    {
      Header: 'User Type',
      accessor: 'userType',
    },
    {
      Header: 'Action',
      accessor: 'action',
    },
    {
      Header: '',
      accessor: 'open',
    },
  ] as Column<TableData>[]);

  useEffect(() => {
    refetchGetAbuseReports()
  }, [searchBody]);

  useEffect(() => {
    dispatchSearch({
      type: 'SET_SEARCH',
      payload: {
        search: searchQuery?.trim(),
      },
    });
  }, [searchQuery]);

  useEffect(() => {
    setPageNumber(1)
    setSearchQuery(propSearchQuery);
  }, [propSearchQuery]);

  const onPageChangedHandler = (pageNumber: number) => {
    setPageNumber(pageNumber);
    dispatchSearch({
      type: 'SET_PAGE',
      payload: {
        page: pageNumber
      },
    });
  };

  useEffect(() => {
    if (hookDataAbuseReports) {
      const response = hookDataAbuseReports;
      setPageCount(Math.ceil(response.total / response.pageSize));
      setData(
        response.data.map(i => {
          const userId = i.userId;

          const name = `${i.user.firstName} ${i.user.lastName}`;

          let location = '';
          if (i.user.filterSetting) {
            const userLocation = i.user.filterSetting.location;
            if (userLocation) {
              location = userLocation?.cityOrMunicipality;
              if (userLocation.hasProvince) {
                location += `, ${userLocation.province.name}`;
              }
            }
          }

          const numberOfReports = i.numberOfReports;

          const userType =
            i.user.userRole === 'homeowner' ? 'Homeowner' : 'Kasambahay';

          const isBanned = i.user.isBanned;

          return {
            userId,
            name,
            location,
            numberOfReports,
            userType,
            isBanned,
          } as TableData;
        }),
      );
    }
  }, [hookDataAbuseReports]);

  const onBanUserClickedHandler = async (rowData: TableData) => {
    try {
      await postBanUser.mutateAsync({userId: rowData.userId});

      systemLogEvent('active_users', {
        kazams:
          (hookDataSystemStatistics?.activeKazams || 0) -
          (authData?.user.userRole === 'kazam' ? 1 : 0),
        homeowners:
          (hookDataSystemStatistics?.activeHomeowners || 0) -
          (authData?.user.userRole === 'homeowner' ? 1 : 0),
      });

      systemLogEvent('system_statistics', {
        ...hookDataSystemStatistics
      });

      presentToast({
        message: 'Banned successfully.',
        variant: 'success',
        position: 'bottom',
      });

      await refetchSystemStatistics();
    } catch (e) {
      console.log(e);
      presentToast({
        message: `Unexpected error was occurred.`,
        variant: 'error',
        position: 'bottom',
      });
    }
  };

  const onSuspendUserClickedHandler = async (rowData: TableData) => {
    try {
      await postSuspendUser.mutateAsync({userId: rowData.userId});

      systemLogEvent('active_users', {
        kazams:
          (hookDataSystemStatistics?.activeKazams || 0) -
          (authData?.user.userRole === 'kazam' ? 1 : 0),
        homeowners:
          (hookDataSystemStatistics?.activeHomeowners || 0) -
          (authData?.user.userRole === 'homeowner' ? 1 : 0),
      });

      systemLogEvent('system_statistics', {
        ...hookDataSystemStatistics,
      });

      presentToast({
        message: 'Suspended successfully.',
        variant: 'success',
        position: 'bottom',
      });

      await refetchSystemStatistics();
      await refetchGetSuspendedUsers();
    } catch (e) {
      console.log(e);
      presentToast({
        message: `Unexpected error was occurred.`,
        variant: 'error',
        position: 'bottom',
      });
    }
  };

  const manualRenderFn = (columnId: string, rowData: TableData) => {
    if (columnId === 'open') {
      return (
        <div className="flex flex-col justify-center items-end">
          <Icon name="chevronRight" color={colorTheme.dark} />
        </div>
      );
    }

    if (rowData.isBanned) {
      return undefined;
    } else {
      return (
        <div className='flex space-x-2'>
          <div
            className="cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              onBanUserClickedHandler(rowData);
            }}>
            <Typography
              label="Ban"
              variant="f2"
              color={colorTheme.primary}
              align="left"
            />
          </div>

          {(hookDataSuspendedUsers?.data.length === 0 || 
            !hookDataSuspendedUsers?.data.find(s => s.userId === rowData.userId)) &&
            <>
              <div>|</div>

              <div
                className="cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  onSuspendUserClickedHandler(rowData)
                }}>
                <Typography
                  label="Suspend"
                  variant="f2"
                  color={colorTheme.primary}
                  align="left"
                />
              </div>
            </>
          }
        </div>
        
      );
    }
  };

  const tableRowClickHandler = (rowData: TableData) => {
    console.log({rowData});
    let url = RoutePath.ADMIN_REPORT_VIEW.toString();
    url = url.replace(':id', rowData.userId);
    history.push(url);
  };

  return (
    <StyledAdminReportUsersTable>
      <AdminTable
        columns={columns}
        data={data}
        pageSize={10}
        pageNumber={pageNumber}
        pageCount={pageCount}
        showDebugView={false}
        rowClassName="table-row"
        onRowClick={tableRowClickHandler}
        onPageChange={onPageChangedHandler}
        manualRenderFn={manualRenderFn}
      />
    </StyledAdminReportUsersTable>
  );
};

export default AdminReportedUsersTable;
