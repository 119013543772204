import {
  Button,
  Typography,
} from '../../../core/components';
import {colorTheme} from '../../../core/configs';
import {useHistory, useLocation} from 'react-router-dom';
import {RoutePath} from '../../navigation/config/RouteConfig';
import Page from '../../../core/components/page/Page';
import logo from '../../../assets/KAZAM_logo_Transparent.svg';
import { Action } from 'history';
import { useAuth } from '../../hooks/useAuth';
import { useGetPersonalityMatch } from '../../server/react-query/usePersonalityMatch';
import { useEffect, useState } from 'react';
import { homeownerPersonalityTest, kasambahayPersonalityTest } from '../../server/data/personality-test-questions';
import _ from 'lodash';
import { STORAGE } from '../../utils/storage.utils';
import { isMobile } from 'react-device-detect';
import { PersonalityTestData } from '../../server/types/personality-test.types';
import { localization } from '../../localization/Localization';

const TakePersonalityTest = () => {
  const history = useHistory();
  const {authData} = useAuth();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const [signUp] = useState<string>(params.get('sign-up') || '');
  
  const isHomeowner = authData?.user?.userRole === 'homeowner'
  const questions = isHomeowner ? homeownerPersonalityTest : kasambahayPersonalityTest

  const { data, isFetching } = useGetPersonalityMatch() 

  const [alreadyCompletedTest, setAlreadyCompletedTest] = useState<boolean>()

  useEffect(() => {
    if (data) {
      var answers = data.answers.split(',')
      if (answers
        && answers.length > 0) {
          var testData = _.cloneDeep(questions);
          answers.forEach((a, i) => {
            testData[i].answer = parseInt(a);
          });
          STORAGE.save('personalityTest', testData, true)
          setAlreadyCompletedTest(testData?.length === 10)
      }
      var currentLanguage = localStorage.getItem('languange')
      localStorage.setItem('previousLanguage', currentLanguage ?? 'en');
      localStorage.setItem('language', 'ph');
    }
  }, [data])

  useEffect(() => {
    if (authData) {
      if(authData.user.userRole === 'kazam') {
        var currentLanguage = localStorage.getItem('languange')
        localStorage.setItem('previousLanguage', currentLanguage ?? 'en');
        localization.setLanguage('ph')
        localStorage.setItem('language', 'ph');
      }
    }
  }, [authData])
  
  const onCancel = () => {
    var previousLanguage = localStorage.getItem('previousLanguange')
    localization.setLanguage(previousLanguage ?? "en")
    localStorage.setItem('language', previousLanguage ?? "en");

    if (history.action === Action.Push) {
      history.go(-1);
    } else {
      history.replace('/');
    }
  }

  const onTakeTest = () => {
    history.push(RoutePath.PERSONALITY_TEST);
  }

  return (
    <Page hideNavbar>
      <div className="space-y-6">
        <div className="flex justify-center">
          <img style={{height: '29px'}} src={logo} alt="Logo" />
        </div>

        <div className='flex flex-col lg:justify-between'
          style={{
            minHeight: isMobile
              ? '100%'
              : '500px'
          }}>
          <div>
            <div className="flex flex-1">
              <Typography
                label={isHomeowner
                  ? localization.personality_test_ho_header
                  : localization.personality_test_kz_header
                }
                variant="f3"
                align="center"
                weight='semibold'
                color={colorTheme.dark}
              />
            </div>
            <div className="pt-1">
              <Typography
                label={localization.personality_test_desc}
                variant="f2"
                align="center"
                color={colorTheme.darkFaded}
              />
            </div>
          </div>
          <div className='fixed bottom-6 left-6 right-6 lg:relative lg:w-full lg:left-0 lg:bottom-0'>
            <div>
              <div>
                <Button
                  label={alreadyCompletedTest ? "Retake Personality Test" : "Take Personality Test"}
                  isLoading={isFetching}
                  color="primary"
                  onClick={onTakeTest}
                />
              </div>
              <div className="pt-4">
                <Button
                  label={!!signUp ? "Maybe Later" : "Cancel"}
                  variant="outlined"
                  onClick={onCancel}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};
export default TakePersonalityTest;
