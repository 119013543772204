import LocalizedStrings from 'react-localization';

export const localization = new LocalizedStrings({
  en: {
    // Header labels
    hdr_landing: 'Welcome to',
    hdr_i_am_kazam: 'Create a Kasambahay Profile',
    hdr_i_am_homeowner: 'Create a Homeowner Profile',
    hdr_otp: 'A one-time PIN (OTP) has been sent to your mobile.',
    hdr_sign_up:
      'You must be 18 years old or above to join kazam. To create your account, enter your mobile number.',
    hdr_reset_pw: 'Reset Password',
    hdr_su_user_info: 'Tell us about yourself!',
    hdr_roles: 'What can you do?',
    hdr_kzm_info: 'Tell us more about yourself!',
    hdr_salary: 'What is your desired salary?',
    hdr_location: 'Where do you currently live?',
    hdr_living_arr: 'Stay-in or Stay-out?',
    hdr_created_acc: 'Your profile has been created!',
    hdr_su_prof_pic: 'Matches are easier to make when they can see you!',
    hdr_about: 'About',
    hdr_chat_list: 'Chat List',
    hdr_fave_list: 'Favorites List',
    hdr_kaway_list: 'Kaways List',
    hdr_search_settings: 'Search Settings',
    hdr_notifs: 'Notifications',
    hdr_experience: 'Experience',
    hdr_profile: 'Profile',
    hdr_my_profile: 'My Profile',
    hdr_edit_profile: 'Edit Profile',
    hdr_settings: 'Settings',
    hdr_lang_settings: 'Language / Wika',
    hdr_subs_details: 'Subscription Details',
    hdr_sort_by: 'Sort By',
    hdr_disabled_profile: 'You’ve disabled your profile!',
    hdr_banned_profile: 'Your profile has been banned!',
    hdr_subscription_history: 'Subscription History',

    // Header Descriptions
    desc_landing_updated: '<b>kazam</b> helps kasambahays and homeowners find each other. No need for an agency.',
    desc_landing: 'Connecting Kasambahays and Homeowners',
    desc_reset_pw: 'Enter mobile number to request a password reset.',
    desc_reset_pw_otp:
      'To confirm your password reset request, please enter the OTP below.',
    desc_otp: 'To confirm your registration, please enter the OTP below.',
    desc_ho_sign_up:
      'For your safety, use a password with letters, numbers, and special characters.',
    desc_kzm_sign_up:
      'For your safety, use a password with letters, numbers, and special characters.',
    desc_pw_reset: 'Enter new password.',
    desc_su_user_info: 'Create your profile by filling in details below.',
    desc_roles: '(select up to 3)',
    desc_kzm_info: 'Complete the details below to improve matches.  ',
    desc_salary:
      'Note: We will not match you with Homeowners who have set salaries that are lower than your minimum.',
    desc_salary_homeo:
      'Note: We will not match you with Kazams who have salaries that are higher than your salary budget.',
    desc_created_acc:
      'Worried about losing your SIM? Protect yourself by entering your email address! You can use this to request new credentials.',
    desc_su_prof_pic:
      'Help them get to know you a little! Upload your best profile picture.',
    desc_no_chats: 'No chats yet.',
    desc_no_favorites: 'No favorites yet.',
    desc_no_kaways: 'No kaways yet.',
    desc_no_notifs: 'No notifications yet.',
    desc_no_matches: 'No matches found yet.',
    desc_no_matches_kazam:
      'Your profile is available for searches by homeowners!  Come back later or tomorrow to see the Homeowners who have matched with you.',
    desc_no_matches_kazam_message:
      'Remember to touch the < and > icons to scroll through the matching Homeowners.  Use the Kaway function to get noticed!',
    desc_search_settings:
      'To show matching Kazams, fill in your Search Settings.',
    desc_upload_pic: 'Click to upload picture',
    desc_profile: 'Click here to view and update your profile',
    desc_search_setting: 'Click here to update your search settings',
    desc_match_notif: 'You have appeared in {firstName}’s search.',
    desc_job_offer_notif: '{firstName} is offering to hire you.  Click to see terms.',
    desc_job_details_notif: '{firstName} updated the job details. Click to see job details.',
    desc_job_ended_notif: '{firstName} initiated {reason}. Click to rate {user}.',
    desc_most_recent: '(Most recent messages first)',
    desc_select_lang: 'Choose system language.',
    desc_lang_settings: 'Set system language.',
    desc_delete_account_note:
      'Note: Your profile will be permanently deleted after 30 days. You can still recover your profile by logging in before then.',
    desc_disclaimer:
      'Stay safe! kazam platform is not responsible for these chat messages.',
    desc_full_disclaimer:
      'All content in profiles and messages on the kazam platform are created by our users.  kazam does not create or check any of the user content.  As a user of the kazam platform, you need to do your own checking to confirm that details or statements from other users are truthful and correct.',
    desc_disabled_profile:
      'To access all kazam features, please enable your account.',
    desc_disable_profile_page:
      'Disabling your profile means you will no longer show up in searches or be matched.  To cancel the request, please click on the back icon.  To proceed, please share your reason for disabling your profile and click Proceed.',
    desc_delete_profile_page:
      'Deleting your profile means you will no longer have access to the <b>kazam platform</b>.  You will no longer be able to see your previous activities in the platform such as chat history.  To cancel the request, please click on the back icon.  To proceed, please share your reason for deleting your profile and click Proceed.',
    desc_subscription_details: 'Subscription details description',
    desc_susbcription_history: 'For any concerns on subscriptions and payments, reach out to us at subscriptions@kazam.ph.',
    desc_get_id_verified: 'Get ID Verified!',
    desc_click_id_verified: 'Click here to get ID verified.',
    desc_capture_true_selfie: 'Capture True Selfie!',
    desc_click_capture_selfie: 'Click here to capture True Selfie',
    desc_personality_test: 'Take Personality Test!',
    desc_click_personality_test: 'Click here to taker personality test.',

    // Buttons
    btn_sign_in: 'Sign In',
    btn_next: 'Next',
    btn_cancel: 'Cancel',
    btn_submit: 'Submit',
    btn_skip: 'Skip',
    btn_save_finish: 'Save & Finish',
    btn_skip_finish: 'Skip & Finish',
    btn_reset: 'Reset',
    btn_save: 'Save',
    btn_copy_ref: 'Copy Referral Link',
    btn_see_matched_ho: 'See matched homeowners',
    btn_continue: 'Continue',
    btn_hide: 'Continue',
    btn_remove: 'Remove',
    btn_enable_acc: 'Enable Profile',
    btn_disable_acc: 'Disable Profile',
    btn_delete_acc: 'Delete Profile',
    btn_enable: 'Enable',
    btn_disable: 'Disable',
    btn_delete: 'Delete',
    btn_proceed: 'Proceed',
    btn_view_profile: 'View Profile',
    btn_agree: 'Agree',
    btn_disagree: 'Disagree',
    btn_refresh: 'Refresh',
    btn_change_password: 'Change Password',
    btn_proceed_payment: 'Proceed to payment',

    // Checkbox
    check_email_updates: 'Send me email updates about matches.',
    check_kazam_lgbtqia: 'Please check if you want to let homeowners know that you are LGBTQIA+.',
    check_include_lgbtqia: 'Include LGBTQIA+ candidates',

    // radio
    rad_all: 'Stay-In or Stay-Out',
    rad_stay_in: 'Stay-In',
    rad_stay_out: 'Stay-Out',
    rad_your_city: 'In your city/municipality',
    rad_nearby: 'Nearby',
    rad_all_matches: 'All matches',
    rad_specific_province: 'Specific province',
    rad_kzm_rsn_1: 'I was already hired by a homeowner here in <b>kazam</b>',
    rad_kzm_rsn_2: 'I was already hired by a homeowner using agency or referral',
    rad_kzm_rsn_3: 'I am not satisfied with the quality of matches',
    rad_kzm_rsn_4: 'I have a concern on privacy',
    rad_kzm_rsn_5: 'I have a concern on safety',
    rad_ho_rsn_1: 'I already hired a kasambahay using <b>kazam</b>',
    rad_ho_rsn_2: 'I already hired a kasambahay using agency or referral',
    rad_ho_rsn_3: 'I am not satisfied with the quality of matches',
    rad_ho_rsn_4: 'Not getting value for my subscription',
    rad_ho_rsn_5: 'I have a concern on privacy',
    rad_ho_rsn_6: 'I have a concern on safety',
    rad_rsn_specify: 'Others (Please Specify)',

    // Placeholders
    ph_enter_mobile: 'Enter mobile number (09xxxxxxxxx)',
    ph_current_pw: 'Enter current password',
    ph_enter_pw: 'Enter password',
    ph_enter_new_pw: 'Enter new password',
    ph_repeat_pw: 'Repeat password',
    ph_enter_otp: 'Enter OTP',
    ph_enter_salary: 'Enter minimum salary',
    ph_enter_max_salary: 'Enter your salary budget',
    ph_search_loc: 'Enter your location',
    ph_enter_email: 'Enter email address',
    ph_enter_first_name: 'Enter first name',
    ph_enter_last_name: 'Enter last name',
    ph_enter_birthdate: 'Enter birthdate',
    ph_select_gender: 'Select sex',
    ph_search_name: 'Search name',
    ph_search_name_uuid: 'Search name or UUID',
    ph_search_province: 'Search province',
    ph_enter_km: 'Enter km',
    ph_write_msg: 'Write a message...',
    ph_select_lang: 'Select language',
    ph_select_reason: 'Select Reason',
    ph_enter_reason: 'Enter Reason',
    ph_select_civil_status: 'Select civil status',
    ph_select_with_children: 'Yes Or No',
    ph_select_language: 'Select known dialect (Max of 3)',
    ph_years_experience: 'YEARS',
    ph_ex_abroad: 'Yes/No',
    ph_country_experience: 'Select country',

    // Input label
    lbl_distance: 'Distance',
    lbl_salary: 'Salary',
    lbl_online_status: 'Online Status',
    lbl_role: 'Role',
    lbl_role_max: 'Role (up to 3)',
    lbl_max_salary: 'Max salary / month',
    lbl_min_salary: 'Min salary / month',
    lbl_living_arr: 'Living arrangement',
    lbl_first_name: 'First name',
    lbl_last_name: 'Last name',
    lbl_birthdate: 'Birthdate',
    lbl_gender: 'Sex',
    lbl_your_loc: 'Your location',
    lbl_mobile_no: 'Mobile number',
    lbl_email: 'Email',
    lbl_language: 'Language',
    lbl_search_area: 'Search area',
    lbl_km_range: 'Kilometer range',
    lbl_new_pw: 'New password',
    lbl_current_pw: 'Current password',
    lbl_confirm_pw: 'Confirm new password',
    lbl_subscription_details: ' Subscription Details',
    lbl_with_children: 'With Children?',

    // Links
    lnk_forgot_pw_desc: 'Forgot password?',
    lnk_reset_pw: 'Reset Password',
    lnk_resend_otp: 'Resend OTP',

    // Side Menu
    sm_chat: 'Chats',
    sm_favorites: 'Favorites',
    sm_kaways: 'Kaways',
    sm_notifications: 'Notifications',
    sm_hiring_history: 'Hiring History',
    sm_job_history: 'Job History',
    sm_settings: 'Settings',
    sm_latest_news: 'Latest News',
    sm_home: 'Home',
    sm_about_us: 'About Us',
    sm_t_and_c: 'Terms & Conditions',
    sm_data_privacy: 'Data Privacy Policy',
    sm_logout: 'Logout',
    sm_get_verified: 'Get ID Verified',
    sm_capture_true_selfie: 'Capture True Selfie',
    sm_take_personality_test: 'Take Personality Test',
    sm_advance_profile: 'Update Role & Skills',
    sm_my_profile: 'My Profile',

    // Dialog header
    dlg_hdr_remove_fave: 'Remove Favorite',
    dlg_hdr_hide_kzm: 'Hide Kazam',
    dlg_hdr_new_kzm: 'You’re all set!',
    dlg_hdr_new_ho: 'Hello Homeowner!',
    dlg_hdr_recover_profile: 'Recover your profile?',
    dlg_hdr_refresh_result: 'Refresh search results?',
    dlg_hdr_hired_tagged: 'HIRED TAGGED',
    dlg_hdr_offer_details: 'OFFER DETAILS',


    //Dialog desc
    dlg_desc_remove_fave:
      'Are you sure you want to remove this kazam as a favorite?',
    dlg_desc_hide_kzm: 'Are you sure you want to hide this Kazam?',
    dlg_desc_new_kzm: 'Kaway to the Homeowners to get noticed.',
    dlg_desc_del_acc: 'Are you sure you want to delete your Profile?',
    dlg_desc_disable_acc: 'Are you sure you want to disable your Profile?',
    dlg_desc_enable_acc: 'Are you sure you want to enable your Profile?',
    dlg_desc_new_ho: 'These are the kazams who sent you kaways.',
    dlg_desc_recover_profile:
      'You’ve recently deleted your Profile. Press continue to recover your Profile.',
    dlg_desc_refresh_result:
      'Click Refresh to repeat your search and check for new matches.',

    'dlg_desc_hired_tagged':'This user has been hired by another homeowner. <br /> Hired {userName} are unavailable for chat for 30 days after hiring to protect the hiring homeowner.',

    ho_pr_i_am: 'I am',
    ho_pr_from: 'from',
    ho_pr_looking: 'I am looking for',
    ho_pr_looking_for: 'Looking for:',
    ho_pr_with: 'with',
    ho_pr_and: 'and',
    ho_pr_setup: 'setup',
    ho_pr_or: 'or',
    ho_pr_per_mo: 'per month',

    kzm_pr_age: 'years old from',
    kzm_pr_apply: 'applying for the',
    kzm_pr_min_salary: 'My minimum expected salary is',
    kzm_pr_per_mo: 'per month on a',
    kzm_pr_role_of: 'role of',
    kzm_pr_roles_of: 'roles of',

    personality_test_ho_header: "Ready ka na bang maghanap ng Kasambahay na para sayo?",
    personality_test_kz_header: "Ready ka na bang maghanap ng Kasambahay na para sayo?",
    personality_test_desc: "Sagutin ang Personality Test ngayon para makita kung sino ang match sa iyong kailangan at personality!",

    personality_test_ho_q1: "In my household, my helper is allowed to join me in family outings, dining out, and/or malling.",
    personality_test_ho_q2: "In my household, my helper is asked about his/her thoughts and feelings about work and about life in general.",
    personality_test_ho_q3: "In my household, my helper is free to show his/her skills and talents in doing his/her job.",
    personality_test_ho_q4: "In my household, my helper is expected to strictly follow all my instructions.",
    personality_test_ho_q5: "In my household, my helper is not exposed to potential hazards and dangers while working.",
    personality_test_ho_q6: "In my household, my helper is provided with his/her own room where they can rest without disturbance.",
    personality_test_ho_q7: "In my household, my helper is taught new skills about how to do household chores.",
    personality_test_ho_q8: "In my household, my helper is praised and criticized when necessary.",
    personality_test_ho_q9: "In my household, my helper is welcome to laugh and joke with me.",
    personality_test_ho_q10: "In my household, my helper is expected to do the job without chatting with other members of the household.",

    personality_test_kz_q1: "How desirable is joining your employer's household in family outings, dining out, malling?",
    personality_test_kz_q2: "How desirable is having an employer who checks in with you about work or life?",
    personality_test_kz_q3: "How desirable is showing your skills and talents at your employer's household?",
    personality_test_kz_q4: "How desirable is ensuring to follow the instructions of your bosses?",
    personality_test_kz_q5: "How desirable is having no visible dangers (hazards) in the vicinity while working?",
    personality_test_kz_q6: "How desirable is having your own space/room to rest, without being interrupted by your employer?",
    personality_test_kz_q7: "How desirable is learning new skills at your employer's household?",
    personality_test_kz_q8: "How desirable is receiving feedback about your work from your employer?",
    personality_test_kz_q9: "How desirable is laughing and joking with your employers?",
    personality_test_kz_q10: "How desirable is only interacting with household members when needed?",

    // On-boarding
    ob_homeowner_pov: "Optional information that will help us match you with the right Kasambahays. (These information will not be displayed in your profile.)",

    advance_profile_header: "Ipakita ang kaya mong gawin!",
    advance_profile_desc: "Sagutan ang Skills section para makita ng Homeowner ang iyong kakayahan!",
    advance_profile_page_header: "Piliin ang skills mo.",
  },
  ph: {
    // Header labels
    hdr_landing: 'Welcome to',
    hdr_i_am_kazam: 'Gumawa ng Kasambahay Profile',
    hdr_i_am_homeowner: 'Gumawa ng Homeowner Profile',
    hdr_otp: 'Nagpadala kami ng one-time PIN (OTP) sa mobile number mo.',
    hdr_sign_up:
      'Ikaw dapat ay 18 taong gulang pataas para gumawa ng kazam profile.  Para magpatuloy, ilagay ang iyong mobile number at napiling password.',
    hdr_reset_pw: 'I-reset ang Password',
    hdr_su_user_info: 'Buoin ang iyong profile!',
    hdr_roles: 'Ano ang kaya mong gawin?',
    hdr_kzm_info: 'Dagdagan ang iyong profile!',
    hdr_salary: 'Magkano ang gusto mong sweldo?',
    hdr_location: 'Saan ka nakatira ngayon?',
    hdr_living_arr: 'Stay-in o Stay-out?',
    hdr_created_acc: 'May profile ka na!',
    hdr_su_prof_pic: 'Mas makakuha ka ng match kung makikita ka nila!',
    hdr_about: 'About',
    hdr_chat_list: 'Chat List',
    hdr_fave_list: 'Favorites List',
    hdr_kaway_list: 'Kaways List',
    hdr_search_settings: 'Search Settings',
    hdr_notifs: 'Notifications',
    hdr_profile: 'Profile',
    hdr_my_profile: 'My Profile',
    hdr_edit_profile: 'Edit Profile',
    hdr_settings: 'Settings',
    hdr_lang_settings: 'Language / Wika',
    hdr_subs_details: 'Subscription Details',
    hdr_sort_by: 'Sort By',
    hdr_disabled_profile: 'Disabled na ang profile mo!',
    hdr_banned_profile: 'Banned na ang profile mo!',
    hdr_subscription_history: 'Subscription History',
    hdr_experience:'Experience',


    // Header Descriptions
    desc_landing_updated: '<b>kazam</b> helps kasambahays and homeowners find each other. No need for an agency.',
    desc_landing: 'Connecting Kasambahays and Homeowners',
    desc_reset_pw: 'Ilagay ang iyong mobile number para i-reset ang password.',
    desc_reset_pw_otp:
      'Para kumpirmahin ang iyong password reset, ilagay ang iyong OTP.',
    desc_otp: 'Para kumpirmahin ang iyong pag-register, ilagay ang iyong OTP.',
    desc_ho_sign_up:
      'Para sa iyong kaligtasan, gumamit ng password na may letra, numero, at special characters.',
    desc_kzm_sign_up:
      'Para sa iyong kaligtasan, gumamit ng password na may letra, numero, at special characters.',
    desc_pw_reset: 'Ilagay ang bagong password.',
    desc_su_user_info: 'Ilagay ang iyong mga detalye sa ibaba.',
    desc_roles: '(pumili ng 1 hanggang 3)',
    desc_kzm_info: 'Gagamitin ito para mas maayos ang pag-match.',
    desc_salary:
      'Note: Hindi ka namin ima-match sa mga Homeowners na mas mababa ang pasweldo sa gusto mo.',
    desc_salary_homeo:
      'Note: Hindi ka namin ima-match sa mga Kazam na mas mataas ang hinahanap na sweldo kesa sa budget mo.',
    desc_created_acc:
      'Takot ka bang mawalan ng SIM?  Ilagay ang iyong email address para protektahan ang sarili mo! Pwede mo itong gamitin para humingi ng bagong credentials.',
    desc_su_prof_pic:
      'Ipakilala ang iyong sarili!  Mag-upload ng maayos na profile picture.',
    desc_no_chats: 'Wala pang chat.',
    desc_no_favorites: 'Wala pang favorite.',
    desc_no_kaways: 'Wala pang Kaway. ',
    desc_no_notifs: 'Wala pang notification.',
    desc_no_matches: 'Wala pang match.',
    desc_no_matches_kazam:
      'Handa na ang profile mo para mahanap ng mga Homeowner!  Siguraduhing bumalik mamaya o bukas para makita ang mga Homeowner na nag match sa iyo.',
    desc_no_matches_kazam_message:
      'Huwag kalimutang pindutin ang < at > upang makita ang mga Homeowner na nag-match.  Kawayan sila para mapansin!',
    desc_search_settings:
      'Para makakita ng ka-match na Kazam, punan ang iyong Search Settings.',
    desc_upload_pic: 'I-click para mag-upload ng profile pic.',
    desc_profile: 'I-click para tingnan at baguhin ang iyong profile.',
    desc_search_setting: 'I-click para palitan dito ang iyong search settings.',
    desc_match_notif: 'Nakita ka sa search ni {firstName}.',
    desc_job_offer_notif: '{firstName} is offering to hire you.  Click to see terms.',
    desc_job_details_notif: '{firstName} updated the job details. Click to see job details.',
    desc_job_ended_notif: '{firstName} initiated {reason}. Click to rate {user}.',
    desc_most_recent: '(Una ang pinakabagong mga chat)',
    desc_select_lang: 'Piliin ang wika ng system.',
    desc_lang_settings: 'I-set ang wika ng system.',
    desc_delete_account_note:
      'Note: Mabubura ang iyong profile pagkatapos ng 30 days.  Mag-login sa loob ng 30 days para ma-recover ang iyong profile.',
    desc_disclaimer:
      'Ingat! Walang pananagutan ang kazam platform sa mga mensahe dito.',
    desc_full_disclaimer:
      'Ang mga profile at chat sa kazam platform ay nilikha ng mga User namin.  Hindi gumagawa o nagsisiyasat ang kazam ng nilalaman ng profile at chat.  Bilang user ng kazam platform, kinakailangan mong suriin ang mga detalye at mensahe dito para siguraduhin na tama at totoo.',
    desc_disabled_profile:
      'Para ma-access mo ang lahat ng features ng kazam, paki-enable ang iyong profile.',
    desc_disable_profile_page:
      'Habang naka-disable ang profile mo, hindi ka mahahanap o maima-match.  Para bawiin ang pag-disable, i-click ang back icon.  Para ituloy, piliin ang dahilan ng pag-cancel at i-click ang Proceed.',
    desc_delete_profile_page:
      'Matapos i-delete ang profile mo, hindi mo na magagamit ang <b>kazam platform</b>.  Hindi mo na makikita ang mga nagawa mo sa platform kagaya ng mga mensahe sa chat.  Para bawiin ang pag-delete, i-click ang back icon. Para ituloy, piliin ang dahilan ng pag-delete at i-click ang Proceed.',
    desc_subscription_details: 'Subscription details description',
    desc_susbcription_history: 'For any concerns on subscriptions and payments, reach out to us at subscriptions@kazam.ph.',
    desc_get_id_verified: 'Get ID Verified!',
    desc_click_id_verified: 'Click here to get ID verified.',
    desc_capture_true_selfie: 'Capture True Selfie!',
    desc_click_capture_selfie: 'Click here to capture True Selfie',
    desc_personality_test: 'Take Personality Test!',
    desc_click_personality_test: 'Click here to taker personality test.',

    // Buttons
    btn_sign_in: 'Sign In',
    btn_next: 'Next',
    btn_cancel: 'Cancel',
    btn_submit: 'Submit',
    btn_skip: 'Skip',
    btn_save_finish: 'Save & Finish',
    btn_skip_finish: 'Skip & Finish',
    btn_reset: 'Reset',
    btn_save: 'Save',
    btn_copy_ref: 'Copy Referral Link',
    btn_see_matched_ho: 'See matched homeowners',
    btn_continue: 'Continue',
    btn_hide: 'Hide',
    btn_remove: 'Remove',
    btn_enable_acc: 'Enable Profile',
    btn_disable_acc: 'Disable Profile',
    btn_delete_acc: 'Delete Profile',
    btn_enable: 'Enable',
    btn_disable: 'Disable',
    btn_delete: 'Delete',
    btn_proceed: 'Proceed',
    btn_view_profile: 'View Profile',
    btn_agree: 'Agree',
    btn_disagree: 'Disagree',
    btn_refresh: 'Refresh',
    btn_change_password: 'Change Password',
    btn_proceed_payment: 'Proceed to payment',

    // Checkbox
    check_email_updates: 'Send me email updates about matches.',
    check_kazam_lgbtqia: 'Please check if you want to let homeowners know that you are LGBTQIA+.',
    check_include_lgbtqia: 'Include LGBTQIA+ candidates',

    // radio
    rad_all: 'Stay-In or Stay-Out',
    rad_stay_in: 'Stay-In',
    rad_stay_out: 'Stay-Out',
    rad_your_city: 'In your city/municipality',
    rad_nearby: 'Nearby',
    rad_all_matches: 'All matches',
    rad_specific_province: 'Specific province',
    rad_kzm_rsn_1: 'Ako ay na-hire na ng isang homeowner dito sa <b>kazam</b>',
    rad_kzm_rsn_2: 'Ako ay na-hire na ng isang homeowner gamit ang ahensya o referral',
    rad_kzm_rsn_3: 'Hindi ako kuntento sa kalidad ng matching',
    rad_kzm_rsn_4: 'May privacy concern ako',
    rad_kzm_rsn_5: 'May security concern ako',
    rad_ho_rsn_1: 'May na-hire na akong kasambahay gamit ang <b>kazam</b>',
    rad_ho_rsn_2: 'May na-hire na akong kasambahay gamit ang ahensya o referral',
    rad_ho_rsn_3: 'Hindi ako kuntento sa kalidad ng matching',
    rad_ho_rsn_4: 'Hindi sulit ang bayad ko sa subscription',
    rad_ho_rsn_5: 'May privacy concern ako',
    rad_ho_rsn_6: 'May security concern ako',
    rad_rsn_specify: 'Iba pa (Pakitukoy)',

    // Placeholders
    ph_enter_mobile: 'Ilagay ang mobile number (09xxxxxxxxx)',
    ph_current_pw: 'Ilagay ang kasalukuyang password',
    ph_enter_pw: 'Ilagay ang password',
    ph_enter_new_pw: 'Ilagay ang bagong password',
    ph_repeat_pw: 'Ilagay muli ang password',
    ph_enter_otp: 'Ilagay ang OTP',
    ph_enter_salary: 'Ilagay ang minimum Salary',
    ph_enter_max_salary: 'Ilagay ang iyong salary budget',
    ph_search_loc: 'Ilagay ang iyong lugar',
    ph_enter_email: 'Ilagay ang email address',
    ph_enter_first_name: 'Ilagay ang first name',
    ph_enter_last_name: 'Ilagay ang last name',
    ph_enter_birthdate: 'Ilagay ang birthdate',
    ph_select_gender: 'Pumili ng kasarian',
    ph_search_name: 'Maghanap gamit ang pangalan',
    ph_search_name_uuid: 'Maghanap gamit ang pangalan o UUID',
    ph_search_province: 'Probinsiya',
    ph_enter_km: 'Ilagay ang km',
    ph_write_msg: 'Magsulat ng mensahe...',
    ph_select_lang: 'Pumili ng wika',
    ph_select_reason: 'Pumili ng dahilan',
    ph_enter_reason: 'Maglagay ng dahilan',
    ph_select_civil_status: 'Select civil status',
    ph_select_with_children: 'Yes Or No',
    ph_select_language: 'Select known dialect (Max of 3)',
    ph_years_experience: 'YEARS',
    ph_ex_abroad: 'Yes Or No',
    ph_country_experience: 'Select country',

    // Input label
    lbl_distance: 'Distance',
    lbl_salary: 'Salary',
    lbl_online_status: 'Online Status',
    lbl_role: 'Role',
    lbl_role_max: 'Role (up to 3)',
    lbl_max_salary: 'Max salary / month',
    lbl_min_salary: 'Min salary / month',
    lbl_living_arr: 'Living arrangement',
    lbl_first_name: 'First name',
    lbl_last_name: 'Last name',
    lbl_birthdate: 'Birthdate',
    lbl_gender: 'Sex',
    lbl_your_loc: 'Your location',
    lbl_mobile_no: 'Mobile number',
    lbl_email: 'Email',
    lbl_language: 'Language',
    lbl_search_area: 'Search area',
    lbl_km_range: 'Kilometer range',
    lbl_new_pw: 'New password',
    lbl_current_pw: 'Current password',
    lbl_confirm_pw: 'Confirm new password',
    lbl_subscription_details: ' Subscription Details',
    lbl_with_children: 'With Children?',

    // Links
    lnk_forgot_pw_desc: 'Nakalimutan ang password?',
    lnk_reset_pw: 'I-reset ang Password',
    lnk_resend_otp: 'Magpadala ulit',

    // Side Menu
    sm_chat: 'Chats',
    sm_favorites: 'Favorites',
    sm_kaways: 'Kaways',
    sm_notifications: 'Notifications',
    sm_hiring_history: 'Hiring History',
    sm_job_history: 'Job History',
    sm_settings: 'Settings',
    sm_latest_news: 'Latest News',
    sm_home: 'Home',
    sm_about_us: 'About Us',
    sm_t_and_c: 'Terms & Conditions',
    sm_data_privacy: 'Data Privacy Policy',
    sm_logout: 'Logout',
    sm_get_verified: 'Get ID Verified',
    sm_capture_true_selfie: 'Capture True Selfie',
    sm_take_personality_test: 'Take Personality Test',
    sm_advance_profile: 'Update Role & Skills',
    sm_my_profile: 'My Profile',

    // Dialog header
    dlg_hdr_remove_fave: 'Tanggalin bilang Favorite',
    dlg_hdr_hide_kzm: 'Itago ang Kazam',
    dlg_hdr_new_kzm: 'Handa ka na!',
    dlg_hdr_new_ho: 'Hello Homeowner!',
    dlg_hdr_recover_profile: 'I-recover ang iyong profile?',
    dlg_hdr_refresh_result: 'Ulitin ang search?',
    dlg_hdr_hired_tagged: 'INUPAHAN NA-TAG',
    dlg_hdr_offer_details: 'Mga Detalye ng Alok',


    //Dialog desc
    dlg_desc_remove_fave:
      'Sigurado kang tatanggalin ang Kazam bilang Favorite?',
    dlg_desc_hide_kzm: 'Sigurado kang itatago ang Kazam?',
    dlg_desc_new_kzm: 'Kawayan ang mga Homeowner para mapansin.',
    dlg_desc_del_acc: 'Sigurado kang buburahin ang iyong Profile?',
    dlg_desc_disable_acc: 'Sigurado kang idi-disable ang iyong Profile?',
    dlg_desc_enable_acc: 'Sigurado kang muling i-e-enable ang iyong Profile?',
    dlg_desc_new_ho: 'These are the kazams who sent you kaways.',
    dlg_desc_recover_profile:
      'Binura mo ang iyong profile.  Pindutin ang continue para i-recover ito.',
    dlg_desc_refresh_result:
      'Click Refresh to repeat your search and check for new matches.',

    'dlg_desc_hired_tagged':'Ang user na ito ay tinanggap ng isa pang may-ari ng bahay. <br /> Ang hired {userName} ay hindi available para sa chat sa loob ng 30 araw pagkatapos mag-hire para protektahan ang hire na may-ari ng bahay.',


    ho_pr_i_am: 'Ako si',
    ho_pr_from: 'mula sa',
    ho_pr_looking: 'Naghahanap ako ng',
    ho_pr_looking_for: 'Naghahanap ng:',
    ho_pr_with: 'na',
    ho_pr_and: 'at',
    ho_pr_setup: '',
    ho_pr_or: 'o',
    ho_pr_per_mo: 'bawat buwan',

    kzm_pr_age: 'taong gulang mula sa',
    kzm_pr_apply: 'na nais maging',
    kzm_pr_min_salary: 'Ang hinahanap kong sweldo ay',
    kzm_pr_per_mo: 'bawat buwan,',
    kzm_pr_role_of: '',
    kzm_pr_roles_of: '',

    personality_test_ho_header: "Ready ka na bang maghanap ng Kasambahay na para sayo?",
    personality_test_kz_header: "Ready ka na bang maghanap ng Homeowner na para sayo?",
    personality_test_desc: "Sagutin ang Personality Test ngayon para makita kung sino ang match sa iyong kailangan at personality!",

    personality_test_ho_q1: "In my household, my helper is allowed to join me in family outings, dining out, and/or malling.",
    personality_test_ho_q2: "In my household, my helper is asked about his/her thoughts and feelings about work and about life in general.",
    personality_test_ho_q3: "In my household, my helper is free to show his/her skills and talents in doing his/her job.",
    personality_test_ho_q4: "In my household, my helper is expected to strictly follow all my instructions.",
    personality_test_ho_q5: "In my household, my helper is not exposed to potential hazards and dangers while working.",
    personality_test_ho_q6: "In my household, my helper is provided with his/her own room where they can rest without disturbance.",
    personality_test_ho_q7: "In my household, my helper is taught new skills about how to do household chores.",
    personality_test_ho_q8: "In my household, my helper is praised and criticized when necessary.",
    personality_test_ho_q9: "In my household, my helper is welcome to laugh and joke with me.",
    personality_test_ho_q10: "In my household, my helper is expected to do the job without chatting with other members of the household.",

    personality_test_kz_q1: "Gaano mo ninanais makasali o makasama sa mga outing ng pinagsisilbihang pamilya, katulad ng pagpunta sa mall at pagkain sa labas?",
    personality_test_kz_q2: "Gaano mo ninanais magkaroon ng pinagsisilbihang pamilya na kinukumusta ka tungkol sa trabaho o sa personal na aspeto?",
    personality_test_kz_q3: "Gaano mo ninanais ipakitang gilas ang mga talento at kakayahan sa iyong trabaho?",
    personality_test_kz_q4: "Gaano mo ninanais tiyakin na sundin ang eksaktong mga bilin ng iyong tahanang pinagsisilbihan?",
    personality_test_kz_q5: "Gaano mo ninanais na walang makita o mapansing posibleng panganib (hazard) habang nagtatrabaho?",
    personality_test_kz_q6: "Gaano mo ninanais na mayroon kang sariling kuwarto kung saan makakapagpahinga nang hindi naiistorbo?",
    personality_test_kz_q7: "Gaano mo ninanais matuto ng mga bagong kaalaman sa trabaho?",
    personality_test_kz_q8: "Gaano mo ninanais makatanggap ng pagpuri at pagpuna mula sa pamilyang pinagsisilbihan?",
    personality_test_kz_q9: "Gaano mo ninanais na pwedeng makipagbiruan at makipagkuwentuhan sa inyong pinagsisilbihang pamilya?",
    personality_test_kz_q10: "Gaano mo ninanais makipag-usap lamang sa mga kasama kung kinakailangan?",

    // On-boarding
    ob_homeowner_pov: "Optional information that will help us match you with the right Kasambahays. (These information will not be displayed in your profile.)",

    advance_profile_header: "Ipakita ang kaya mong gawin!",
    advance_profile_desc: "Sagutan ang Skills section para makita ng Homeowner ang iyong kakayahan!",
    advance_profile_page_header: "Piliin ang skills mo.",
  },
});
