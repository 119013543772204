import { Icon, Typography } from "../../../../core/components";
import Pill from "../../../../core/components/pill";
import { colorTheme } from "../../../../core/configs";
import { UserResponse } from "../../../server/types";
import { StyledSection, StyledSeeMore } from "./styles";
import Placeholder from '../../../../assets/placeholder.png';

export interface SeeMoreProps {
  onClick?: () => void
}

const SeeMore = ({
  onClick
}: SeeMoreProps) => {
  return (
    <StyledSeeMore>
      <div className="wrapper cursor-pointer"
        onClick={onClick}>
        <Icon
          name="arrowDown"
          size={24}
          color={colorTheme.darkFaded}
          type="button"
        />
      </div>
    </StyledSeeMore>
  );
};

export default SeeMore;
