import { Typography } from "../../../../core/components";
import { colorTheme } from "../../../../core/configs";
import KazamExperience from "../../../components/kazam-experience/KazamExperience";
import { JobHistoryResponse } from "../../../server/types/job-offer.types";
import PlaceholderSection from "./PlaceholderSection";
import { StyledSection } from "./styles";
import Placeholder from '../../../../assets/job-history-placeholder.png';


export interface JobHistorySectionProps {
  jobHistory: JobHistoryResponse[]
  showExtendedProfile?: boolean
}

const JobHistorySection = ({
  jobHistory,
  showExtendedProfile
}: JobHistorySectionProps) => {

  if (jobHistory.length == 0) return <></>;
  
  if (!showExtendedProfile) return <PlaceholderSection label="Kazam Job History" asset={Placeholder}/>;

  return (
    <StyledSection >
      <Typography
        className='label'
        label="Kazam Job History"
        variant="f1"
        weight="semibold"
        color={colorTheme.darkFaded}
      />
      
      <div className='bordered'>
        <KazamExperience
          hideLabel
          jobHistory={jobHistory}
        />
      </div>
    </StyledSection>
  );
};

export default JobHistorySection;
