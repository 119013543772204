import styled from 'styled-components';
import { colorTheme } from '../../../core/configs';

export const StyledRoleSkillsDiv = styled.div`
  border: 1px solid ${colorTheme.darkFaded};
  border-radius: 15px;
  padding: 12px;

  .exp , .exAbroad {
  width: 150px;
  border-radius: 4px;
  justify-content: center}

  .country {
    width: 100%;
    border-radius: 4px;
  }

  .roleDetails {
    top: 14px;
    right: 12px;
    bottom: 14px;
    left: 12px;
    padding: 12px;
  }

  .skills {
    gap: 17.5px;
    font-size: 14px;
    left-margin: 25px; 
  }

  .checkbox {
    font: Poppins;
    font-sizr: 14px;
  }
`;