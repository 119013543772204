import {useEffect, useState} from 'react';
import Page from '../../../../core/components/page/Page';
import {
  Checkbox,
  ImageUpload,
  Input,
  Select,
  SeparatedDatePickerComponent,
  Typehead,
  Typography,
} from '../../../../core/components';
import {colorTheme} from '../../../../core/configs';
import {Controller, useForm} from 'react-hook-form';
import {
  PutKazamProfileBody,
  UploadPhotoReqBody,
  LocationResponse,
  Role,
  KazamRole,
  genderList,
  Gender,
  KazamException,
  civilStatusList,
  withChildren,
  languageDialectList,
} from '../../../server/types';
import {yupResolver} from '@hookform/resolvers/yup';
import {editKazamProfileSchema} from '../../../schema/profile.schema';
import {
  getLocations,
  useGetAdminControl,
  usePutKazamProfile,
  useUploadProfilePhoto,
} from '../../../server/react-query';
import {useHistory} from 'react-router-dom';
import {useQuery} from 'react-query';
import {format} from 'date-fns';
import {getGenderString} from '../../../utils/random.util';
import {useAuth} from '../../../hooks/useAuth';
import {isMobile} from 'react-device-detect';
import {LivingArrangement} from '../../../server/types/filter-settings.types';
import {Nullable} from '../../../types/types';
import EmploymentComponent from '../../finder-settings/EmploymentComponent';
import {localization} from '../../../localization/Localization';
import {StyledInputLabel} from './styled';
import { useToast } from '../../../../core/components/toast';
import RoleComponent from '../../finder-settings/RoleComponent';
import { RoutePath } from '../../../navigation/config/RouteConfig';
import MultiSelect from '../../../../core/components/multi-select';

interface RoleState {
  roleId: string;
  text: string;
  value: number;
  state: boolean;
}

const EditProfileSetupPage = () => {
  const history = useHistory();
  const {updateAuthData, authData} = useAuth();
  const {presentToast} = useToast();
  
  const [kazamRoles, setKazamRoles] = useState<KazamRole[]>([]);
  const [roles, setRoles] = useState<RoleState[]>([]);
  const [rolesList, setRolesList] = useState<Role[]>([]);
  const [locations, setLocations] = useState<LocationResponse[]>([]);
  const [selectedLocation, setselectedLocation] = useState<LocationResponse>();
  const [photo, setPhoto] = useState<UploadPhotoReqBody>({
    image: null,
  });
  const [fetchedPhoto, setFetchedPhoto] = useState('');
  const [showRoleError, setShowRoleError] = useState(false);
  const [livingArrangement, setLivingArrangement] = useState<LivingArrangement>('stayIn');

  const [fetchRoles, setFetchRoles] = useState(false);
  const [fetchLocations, setFetchLocations] = useState(false);
  const [selectedRoleIds, setSelectedRoleIds] = useState<string[]>([]);

  const {data: enableLgbtqia} = useGetAdminControl('enableLgbtqia');

  const {data: locationsData, isFetching: isLocationFetching} = useQuery(
    ['get-locations'],
    getLocations,
    {
      enabled: fetchLocations,
    },
  );

  useEffect(() => {
    var locations = localStorage.getItem('locations');
    if (locations) {
      var locationsData = JSON.parse(locations) as LocationResponse[];
      setLocations(locationsData);
    } else {
      setFetchLocations(true);
    }

    setData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (locationsData && locationsData.length > 0) {
      setLocations(locationsData);
      localStorage.setItem('locations', JSON.stringify(locationsData));
    }
  }, [locationsData]);

  const setData = () => {
    const profileInfo = authData?.user;
    console.log("reset");
    
    if (profileInfo) {
      const birthDate = format(
        new Date(profileInfo.birthDate.toString()),
        'M/dd/yyyy',
      );
      const gender = getGenderString(profileInfo.gender as Gender);
      const locationId = profileInfo.filterSetting.location
        ? profileInfo.filterSetting.location.locationId
        : '';
      const minSalary = profileInfo.filterSetting.salary;
      const roles = profileInfo.filterSetting.kazamRoles?.map(
        kazamRole => kazamRole.role.roleId,
      );
      const withChildren = profileInfo.withChildren ? "Yes" : "No";
      const livingArrangement = profileInfo.filterSetting?.livingArrangement;
      setSelectedRoleIds(roles)

      reset({
        ...profileInfo,
        gender,
        birthDate,
        locationId,
        minSalary,
        roles,
        withChildren,
        livingArrangement
      });
      setFetchedPhoto(profileInfo.photo);
      setKazamRoles(profileInfo.filterSetting.kazamRoles);
      setselectedLocation(profileInfo.filterSetting?.location);
      setLivingArrangement(profileInfo.filterSetting?.livingArrangement);
    }
  }

  const {
    control,
    getValues,
    setValue,
    handleSubmit,
    reset,
    formState: {errors},
    setError,
  } = useForm<PutKazamProfileBody>({
    resolver: yupResolver(editKazamProfileSchema),
    defaultValues: {
      mobileNumber: '',
      firstName: '',
      lastName: '',
      birthDate: '',
      gender: '',
      civilStatus: '',
      withChildren: '',
      languages: ['Tagalog'],
      email: '',
      minSalary: 1,
      locationId: '',
      livingArrangement: 'stayIn',
      lgbtqia: false
    },
  });

  const putKazamProfile = usePutKazamProfile(getValues());
  const uploadMutation = useUploadProfilePhoto(photo);

  const updateRole = (data: any) => {
    if (roles.length > 0) {
      let items = [...roles];
      let item = items[data.i];
      item.state = data.state;
      items[data.i] = item;
      setRoles(items);

      var selectedRoles = roles
        .filter((role: {state: boolean}) => role.state === true)
        .map(role => {
          return role.roleId;
        });

      setValue('roles', selectedRoles);
    }
  };

  const submitForm = async () => {
    handleRoleError() && handleSubmit(handleSave)();
  };

  const handleRoleError = () => {
    const roles = getValues().roles || [];
    return roles.length > 0 && roles.length <= 3;
  };

  const handleSave = async () => {
    try {
      if (photo.image) {
        await uploadMutation.mutateAsync();
      }

      var user = await putKazamProfile.mutateAsync();
      let data = authData;
      if (data) {
        data.user = user;
        updateAuthData(data);
      }

      history.go(-1);
      // history.replace(RoutePath.MY_PROFILE);
    } catch (e: any) {
      console.log('error', e);
      const exception = e.data as KazamException;

      if (exception) {
        if (e.status
          && e.status === 403
          && exception.errorCode === 'SalaryLimitError') {
            setError("minSalary", {message: exception.error});
        }
        presentToast({
          message: exception.error,
          variant: 'error',
          position: 'bottom',
        });
      } else {
        presentToast({
          message: "Failed to save.",
          variant: 'error',
          position: 'bottom',
        });
      }
    }
  };

  const onSelectPhoto = (file: any | null) => {
    setPhoto({
      image: file,
    });
  };

  const selectedEmploymentHandler = (value: LivingArrangement) => {
    setValue('livingArrangement', value);
  };

  const onSalaryChange = (value: any) => {
    var data = value.currentTarget.value.replace(/,/g, '');
    setValue('minSalary', parseFloat(data));
  };

  const selectedRoleHandler = (value: string[]) => {
    setValue('roles', value);
  };

  const goToCurrentLocation = () => {
    history.push(RoutePath.SAVE_CURRENT_LOCATION);
  }

  return (
    <Page
      showBackButton
      showDesktopBackButton
      showRightText={isMobile}
      showDesktopSaveButton={!isMobile}
      onRightActionClick={submitForm}
      rightActionIsLoading={
        putKazamProfile.isLoading || uploadMutation.isLoading
      }>
      <div className="space-y-4">
        <Typography
          label={localization.hdr_edit_profile}
          variant="f3"
          weight="semibold"
          align="center"
          color={colorTheme.dark}
        />
        <div className="space-y-4">
          <div className="flex justify-center">
            <ImageUpload
              onSelectPhoto={onSelectPhoto}
              providedPhoto={fetchedPhoto}></ImageUpload>
          </div>
          <div>
            <Controller
              control={control}
              name="firstName"
              render={({field: {value = '', onBlur, onChange}}) => (
                <Input
                  label={localization.lbl_first_name}
                  inputSize="large"
                  placeholder={localization.ph_enter_first_name}
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  error={errors.firstName?.message}
                  maxLength={20}
                />
              )}
            />
          </div>
          <div>
            <Controller
              control={control}
              name="lastName"
              render={({field: {value = '', onBlur, onChange}}) => (
                <Input
                  label={localization.lbl_last_name}
                  inputSize="large"
                  placeholder={localization.ph_enter_last_name}
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  error={errors.lastName?.message}
                  maxLength={20}
                />
              )}
            />
          </div>
          <div>
            <Controller
              control={control}
              name="birthDate"
              render={({field: {value, onBlur, onChange}}) => (
                // !isMobile ? (
                //   <DatePicker
                //     value={new Date(value)}
                //     onChange={onChange}
                //     placeholder={localization.ph_enter_birthdate}
                //     error={errors.birthDate?.message}
                //   />
                // ) : (
                //   <Input
                //     className={value ? 'has-value' : 'empty date-class'}
                //     label={localization.lbl_birthdate}
                //     inputSize="large"
                //     type={!isMobile ? 'text' : 'date'}
                //     placeholder={localization.ph_enter_birthdate}
                //     value={value ? value.toString() : ''}
                //     onFocus={e => {
                //       e.currentTarget.type = 'date';
                //       e.currentTarget.click();
                //     }}
                //     onBlur={e =>
                //       value && !isMobile
                //         ? (e.currentTarget.type = 'date')
                //         : e.currentTarget.blur()
                //     }
                //     onChange={onChange}
                //     error={errors.birthDate?.message}
                //   />
                // )
                <SeparatedDatePickerComponent
                  label={localization.lbl_birthdate}
                  value={new Date(value)}
                  onChange={onChange}
                  placeholder={localization.ph_enter_birthdate}
                  error={errors.birthDate?.message}
                />
              )}
            />
          </div>
          <div>
            <Controller
              control={control}
              name="gender"
              render={({field: {value, onChange}}) => (
                <Select
                  label={localization.lbl_gender}
                  placeholder={localization.ph_select_gender}
                  items={genderList}
                  itemRenderer={(item, selected) => (
                    <Typography
                      label={item}
                      variant="f1"
                      color={colorTheme.dark}
                      weight={selected ? 'semibold' : 'normal'}
                      className="flex-1"
                    />
                  )}
                  onChange={onChange}
                  value={value}
                  valueRenderer={value => value}
                  error={errors.gender?.message}
                />
              )}
            />
          </div>
          {enableLgbtqia && enableLgbtqia.enabled && (
            <div>
              <Controller
                control={control}
                name="lgbtqia"
                render={({field: {value = false, onChange}}) => (
                  <div className="flex">
                    <Checkbox
                      checked={authData?.user?.filterSetting?.lgbtqia}
                      onChange={onChange}
                      color={'default'}
                    />
                    <div className="flex space-x-1">
                      <Typography
                        label={localization.check_kazam_lgbtqia}
                        variant="f1"
                        align="left"
                        color={colorTheme.darkFaded}></Typography>
                    </div>
                  </div>
                )}
              />
            </div>
          )}
          <div>
            <Controller
              control={control}
              name="civilStatus"
              render={({field: {value, onChange}}) => (
                <Select
                  placeholder={localization.ph_select_civil_status}
                  items={civilStatusList}
                  itemRenderer={(item, selected) => (
                    <Typography
                      label={item}
                      variant="f1"
                      color={colorTheme.dark}
                      weight={selected ? 'semibold' : 'normal'}
                      className="flex-1"
                    />
                  )}
                  onChange={onChange}
                  value={value}
                  valueRenderer={value => value}
                  error={errors.civilStatus?.message}
                />
              )}
            />
          </div>
          <div>
            <Controller
              control={control}
              name="withChildren"
              render={({field: {value, onChange}}) => (
                <Select
                  label={localization.lbl_with_children}
                  placeholder={localization.ph_select_with_children}
                  items={withChildren}
                  itemRenderer={(item, selected) => (
                    <Typography
                      label={item}
                      variant="f1"
                      color={colorTheme.dark}
                      weight={selected ? 'semibold' : 'normal'}
                      className="flex-1"
                    />
                  )}
                  onChange={onChange}
                  value={value}
                  valueRenderer={value => value}
                  error={errors.withChildren?.message}
                />
              )}
            />
          </div>
          <div>
            <Controller
              control={control}
              name="languages"
              render={({field: {value, onChange}}) => (
                <MultiSelect
                  // label={localization.lbl_with_children}
                  placeholder={localization.ph_select_language}
                  items={languageDialectList}
                  itemRenderer={(item, selected) => (
                    <Typography
                      label={item}
                      variant="f1"
                      color={colorTheme.dark}
                      weight={selected ? 'semibold' : 'normal'}
                      className="flex-1"
                    />
                  )}
                  onChange={onChange}
                  value={value ?? []}
                  valueRenderer={(selected) => selected.join(", ")}
                  error={errors.languages?.message}
                />
              )}
            />
          </div>

          <div>
            <Controller
              control={control}
              name="mobileNumber"
              render={({field: {value = '', onBlur, onChange}}) => (
                <Input
                  disabled
                  label={localization.lbl_mobile_no}
                  type="tel"
                  inputSize="large"
                  placeholder={localization.ph_enter_mobile}
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  error={errors.mobileNumber?.message}
                />
              )}
            />
          </div>
          <div>
            <Controller
              control={control}
              name="email"
              render={({field: {value = '', onBlur, onChange}}) => (
                <Input
                  label={localization.lbl_email}
                  type="email"
                  inputSize="large"
                  placeholder={localization.ph_enter_email}
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  error={errors.email?.message}
                />
              )}
            />
          </div>
          <div>
            <div className="space-y-1 pb-2">
              <StyledInputLabel data-testid="label-element">
                {localization.lbl_min_salary}
              </StyledInputLabel>
              {/* <Typography
                label={localization.desc_salary}
                variant="f1"
                align="left"
                color={colorTheme.danger}
              /> */}
            </div>
            <Controller
              control={control}
              name="minSalary"
              render={({field: {value = '', onBlur, onChange}}) => (
                <Input
                  type="number"
                  inputSize="large"
                  placeholder={localization.ph_enter_salary}
                  value={value}
                  onBlur={onBlur}
                  onChange={onSalaryChange}
                  error={errors.minSalary?.message}
                  showThousandSeparator
                />
              )}
            />
          </div>
          <div>
            {locations && locations.length > 0 && (
              <>
                <Controller
                  control={control}
                  name="locationId"
                  render={({field: {value, onChange}}) => (
                    <Typehead
                      label={localization.lbl_your_loc}
                      items={locations}
                      placeholder={localization.ph_search_loc}
                      onChange={sel => {
                        var item = sel && sel.length > 0 ? sel[0].locationId : '';
                        onChange(item);
                      }}
                      selected={selectedLocation}
                      error={errors.locationId?.message}></Typehead>
                  )}
                />
                <div onClick={goToCurrentLocation}>
                  <Typography
                    className="cursor-pointer"
                    label="Use current location"
                    variant="f1"
                    weight="normal"
                    color={colorTheme.primary}
                  />
                </div>
              </>
            )}
          </div>

          <div>
            <EmploymentComponent
              value={livingArrangement}
              onChange={selectedEmploymentHandler}
            />
          </div>
          <div className="space-y-4 pb-6">
            <RoleComponent
              value={selectedRoleIds}
              onChange={selectedRoleHandler}
            />
          </div>
        </div>
      </div>
    </Page>
  );
};

export default EditProfileSetupPage;
