import * as React from 'react';
import {SVGProps, Ref, forwardRef, memo} from 'react';
const SvgAirplane = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 25 25"
    ref={ref}
    {...props}>
    <g fill="currentColor" clipPath="url(#airplane_svg__a)">
      <path d="M24.597 5.94a1.62 1.62 0 0 0-1.267-1.333l-3.913-.86a3 3 0 0 0-2.434.526L5.417 12.7l-3.24-.133a1.24 1.24 0 0 0-.82 2.206l3.333 2.62c.4.487.667.394 7.287-3.213l.62 6.28a.907.907 0 0 0 .926.853c.248-.007.485-.104.667-.273l1.593-1.453c.159-.147.266-.341.307-.554l1.46-7.933c2.38-1.333 4.633-2.587 6.24-3.5a1.62 1.62 0 0 0 .807-1.66Zm-1.467.5c-1.667.947-4 2.273-6.507 3.647l-.273.153-1.553 8.447-.98.893-.734-7.533-.886.453c-4.78 2.667-6.374 3.44-6.92 3.68L2.41 13.907l3.387.146 12-8.706a1.667 1.667 0 0 1 1.333-.3l3.9.84a.286.286 0 0 1 .233.246.28.28 0 0 1-.133.307Z" />
      <path d="m5.417 8.86 2.373.667 1.093-.794-2.666-.773 1.2-.733 3.646-.107 1.534-1.113-5.18.16a.833.833 0 0 0-.467.113L5.197 7.3a.853.853 0 0 0 .22 1.56Z" />
    </g>
    <defs>
      <clipPath id="airplane_svg__a">
        <path fill="#fff" d="M.75.5h24v24h-24z" />
      </clipPath>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(SvgAirplane);
const Memo = memo(ForwardRef);
export default Memo;
