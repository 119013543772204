import React, { useEffect, useState } from 'react';
import { Toggle, Typography } from '../../../../core/components';
import { AdminControl, AdminControlReqBody, DurationContent } from '../../../server/types/admin-control.types';
import { StyledWrapper } from './styled';
import { colorTheme } from '../../../../core/configs';
import { KazamException } from '../../../server/types';
import { useToast } from '../../../../core/components/toast';
import { usePutAdminControl } from '../../../server/react-query';

interface LgbtqiaControlViewProps {
  data: AdminControl[];
}

const LgbtqiaControlView = ({data}: LgbtqiaControlViewProps) => {
  const { presentToast } = useToast();

  const [enableLgbtqia, setEnableLgbtqia] = useState(false);
  const [control, setControl] = useState<AdminControl | undefined>()

  const putAdminControl = usePutAdminControl();

  useEffect(() => {
    if (data) {
      var lgbtqiaControl = data.find(d => d.type === 'enableLgbtqia');
      if (lgbtqiaControl) {
        setControl(lgbtqiaControl)
        setEnableLgbtqia(lgbtqiaControl.enabled);
      }
    }
  }, [data])

  useEffect(() => {
    if (control) {
      setEnableLgbtqia(control.enabled);
    }
  }, [control])

  const handleToggle = (toggled: boolean) => {
    setEnableLgbtqia(toggled)    
    if (control) {
      const data: AdminControlReqBody = {
        type: control.type,
        enabled: toggled,
        content: control.content,
      };
      
      onPutAdminControl(data);
    }
  }

  const onPutAdminControl = async (data: AdminControlReqBody) => {
    try {
      const res = await putAdminControl.mutateAsync(data);
      setControl(res)
      presentToast({
        message: `Successfully saved.`,
        variant: 'success',
        position: 'bottom',
      });
    } catch (e: any) {
      console.log(e);
      const exception = e.data as KazamException;
      presentToast({
        message: exception.error ?? "Failed to save.",
        variant: 'error',
        position: 'bottom',
      })
    }
  };

  return (
    <StyledWrapper>
      <Typography
        label="KAZAM SETTINGS"
        variant="f1"
        weight="semibold"
        color={colorTheme.black}
      />

      <div className="pt-3 flex flex-row justify-between items-center">
        <div>
          <Typography
            label="Optional LGBTQIA+ Indicator Feature"
            variant="f3"
            color={enableLgbtqia ? colorTheme.dark : colorTheme.lightGray}
          />
          <Typography
            label="Set the LGBTQIA+ Indicator feature on and off."
            variant="f1"
            color={enableLgbtqia ? colorTheme.darkFaded : colorTheme.lightGray}
          />
        </div>
        <div>
          <Toggle value={enableLgbtqia} onToggle={handleToggle} />
        </div>
      </div> 
    </StyledWrapper>
  );
};

export default LgbtqiaControlView;