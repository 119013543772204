import {yupResolver} from '@hookform/resolvers/yup';
import {Controller, useForm} from 'react-hook-form';
import {
  Button,
  DatePicker,
  Icon,
  Input,
  Toggle,
  Typography,
} from '../../../../core/components';
import {colorTheme} from '../../../../core/configs';
import {postSubscriptionSchema} from '../../../schema/admin.schema';
import {KazamException} from '../../../server/types';
import {
  AdvertisementType,
  SubscriptionType,
} from '../../../server/types/admin-control.types';
import {StyledLabel, StyledTargetComponent, SyledContent} from './styles';
import { PostSubscriptionBody } from '../../../server/types/subscription.types';
import { usePostSubscription } from '../../../server/react-query/useSubscription';
import { RadioButton, RadioButtonFilled } from '../../../../core/assets/icons/components';
import { useState } from 'react';

interface CreateSubscriptionModalViewProps {
  type: AdvertisementType;
  onClose: () => void;
  onCancel: () => void;
  onCreated: () => void;
  onError?: (message: string) => void;
}

const CreateSubscriptionModalView = (props: CreateSubscriptionModalViewProps) => {
  const postSubscription = usePostSubscription();

  const [selectedType, setSelectedType] = useState<SubscriptionType>(
      'credit',
  );
  const [enableAdvancefilter, setEnableAdvancefilter] = useState<boolean>(false);
  

  const {
    control,
    getValues,
    handleSubmit,
    setValue,
    setError,
    reset,
    formState: {errors},
  } = useForm<PostSubscriptionBody>({
    resolver: yupResolver(postSubscriptionSchema),
    defaultValues: {
      name: '',
      type: selectedType,
      advanceFiltersEnabled: enableAdvancefilter
    },
    shouldUnregister: true
  });

  const submitForm = async () => {
    console.log(getValues());
    
    handleSubmit(handleCreate)();
  };

  const handleCreate = async () => {
    try {
      await postSubscription.mutateAsync(getValues());
      props.onCreated();
    } catch (e: any) {
      console.log('error', e);
      const exception = e.data as KazamException;
      props.onError && props.onError(exception ? exception.error : "Failed to add.")
    }
  };

  const onSelectType = (value: SubscriptionType) => {
    setSelectedType(value);
    reset();
    setValue('type', value);
  };

  const onToggle = (value: boolean) => {
    setEnableAdvancefilter(value);
    setValue('advanceFiltersEnabled', value);
  };

  return (
    <div className="flex flex-col items-center px-4 pb-4 space-y-2">
      <div className="flex w-full justify-between items-center">
        <Typography
          label="Add Subscription"
          variant="f3"
          weight="semibold"
          color={colorTheme.dark}
        />
        <Icon
          name="x"
          color={colorTheme.dark}
          size={24}
          type="button"
          onClick={props.onClose}
          disabled={postSubscription.isLoading}
        />
      </div>

      <SyledContent>
        <div className="space-y-2">
        <StyledTargetComponent>
            <StyledLabel data-testid="label-element">Type</StyledLabel>
            <div className="flex space-x-4">
              <div
                className="cursor-pointer"
                style={{width: 'fit-content'}}
                onClick={() => onSelectType('credit')}>
                <div className="flex flex-row items-center">
                  {selectedType === 'credit' ? (
                    <RadioButtonFilled
                      className="radio-button"
                      color={colorTheme.dark}
                    />
                  ) : (
                    <RadioButton
                      className="radio-button"
                      color={colorTheme.dark}
                    />
                  )}
                  <Typography
                    label="Credit Plan"
                    variant="f2"
                    color={colorTheme.dark}
                  />
                </div>
              </div>

              <div
                className="cursor-pointer"
                style={{width: 'fit-content'}}
                onClick={() => onSelectType('timeBound')}>
                <div className="flex flex-row items-center">
                  {selectedType === 'timeBound' ? (
                    <RadioButtonFilled
                      className="radio-button"
                      color={colorTheme.dark}
                    />
                  ) : (
                    <RadioButton
                      className="radio-button"
                      color={colorTheme.dark}
                    />
                  )}
                  <Typography
                    label="Time Bound Plan"
                    variant="f2"
                    color={colorTheme.dark}
                  />
                </div>
              </div>
            </div>
          </StyledTargetComponent>

          {selectedType == 'credit' ? (
            <>
              <div className="flex justify-between space-x-6">
                <div className="w-full">
                  <Controller
                    control={control}
                    name="name"
                    key="name"
                    render={({field: {value = '', onBlur, onChange}}) => (
                      <Input
                        label="Name"
                        inputSize="large"
                        placeholder="Enter subscription name"
                        value={value}
                        onBlur={onBlur}
                        onChange={onChange}
                        error={errors.name?.message}
                      />
                    )}
                  />
                </div>
                <div style={{width: "250px"}}>
                  <Controller
                    control={control}
                    name="amount"
                    key="amount"
                    render={({field: {value = '', onBlur, onChange}}) => (
                      <Input
                        label="Amount"
                        inputSize="large"
                        placeholder="Enter amount"
                        type='number'
                        value={
                          typeof value === 'number'
                            ? value
                            : isNaN(parseFloat(value as string))
                            ? ''
                            : parseFloat(value as string)
                        }
                        onBlur={onBlur}
                        onChange={onChange}
                        error={errors.amount?.message}
                      />
                    )}
                  />
                </div>
              </div>

              <div className="flex justify-between space-x-6">
                  <div className="w-full">
                    <Controller
                      control={control}
                      name="creditLimit"
                      key="creditLimit"
                      render={({field: {value = '', onBlur, onChange}}) => (
                        <Input
                          label="Maximum Reply Limit"
                          inputSize="large"
                          placeholder="Enter reply limit"
                          value={
                            typeof value === 'number'
                              ? value
                              : isNaN(parseFloat(value as string))
                              ? ''
                              : parseFloat(value as string)
                          }
                          onBlur={onBlur}
                          onChange={onChange}
                          error={errors.creditLimit?.message}
                        />
                      )}
                    />
                  </div>
                  <div className="w-full">
                    <Controller
                      control={control}
                      name="priority"
                      key="priority"
                      render={({field: {value = '', onBlur, onChange}}) => (
                        <Input
                          label="Priority"
                          inputSize="large"
                          placeholder="Enter priority number"
                          type='number'
                          value={
                            typeof value === 'number'
                              ? value
                              : isNaN(parseFloat(value as string))
                              ? ''
                              : parseFloat(value as string)
                          }
                          onBlur={onBlur}
                          onChange={onChange}
                          error={errors.priority?.message}
                        />
                      )}
                    />
                  </div>
                </div>
            </>
          ) : (
            <>
              <div>
                <Controller
                  control={control}
                  name="name"
                  key="name"
                  render={({field: {value = '', onBlur, onChange}}) => (
                    <Input
                      label="Name"
                      inputSize="large"
                      placeholder="Enter subscription name"
                      value={value}
                      onBlur={onBlur}
                      onChange={onChange}
                      error={errors.name?.message}
                    />
                  )}
                />
              </div>

              <div className="flex justify-between space-x-6">
                  <div className="w-full">
                    <Controller
                      control={control}
                      name="period" 
                      key="period"
                      render={({field: {value = '', onBlur, onChange}}) => (
                        <Input
                          label="Validity"
                          inputSize="large"
                          placeholder="Enter number of days"
                          value={
                            typeof value === 'number'
                              ? value
                              : isNaN(parseFloat(value as string))
                              ? ''
                              : parseFloat(value as string)
                          }
                          onBlur={onBlur}
                          onChange={onChange}
                          error={errors.period?.message}
                        />
                      )}
                    />
                  </div>
                  <div className="w-full">
                    <Controller
                      control={control}
                      name="amount"
                      render={({field: {value = '', onBlur, onChange}}) => (
                        <Input
                          label="Amount"
                          inputSize="large"
                          placeholder="Enter amount"
                          type='number'
                          value={
                            typeof value === 'number'
                              ? value
                              : isNaN(parseFloat(value as string))
                              ? ''
                              : parseFloat(value as string)
                          }
                          onBlur={onBlur}
                          onChange={onChange}
                          error={errors.amount?.message}
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="flex justify-between space-x-6">
                  <div className="w-full">
                    <Controller
                      control={control}
                      name="availLimit"
                      render={({field: {value = '', onBlur, onChange}}) => (
                        <Input
                          label="Availment Limit (per user)"
                          inputSize="large"
                          placeholder="Enter availment limit"
                          value={
                            typeof value === 'number'
                              ? value
                              : isNaN(parseFloat(value as string))
                              ? ''
                              : parseFloat(value as string)
                          }
                          onBlur={onBlur}
                          onChange={onChange}
                          error={errors.availLimit?.message}
                        />
                      )}
                    />
                  </div>
                  <div className="w-full">
                    <Controller
                      control={control}
                      name="priority"
                      render={({field: {value = '', onBlur, onChange}}) => (
                        <Input
                          label="Priority"
                          inputSize="large"
                          placeholder="Enter priority number"
                          type='number'
                          value={
                            typeof value === 'number'
                              ? value
                              : isNaN(parseFloat(value as string))
                              ? ''
                              : parseFloat(value as string)
                          }
                          onBlur={onBlur}
                          onChange={onChange}
                          error={errors.priority?.message}
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="flex justify-between space-x-6">
                  <div className="w-full">
                    <Controller
                      control={control}
                      name="startDate"
                      render={({field: {value, onBlur, onChange}}) => (
                        <DatePicker
                          label="Start Date"
                          placeholder="mm/dd/yyyy"
                          value={value}
                          onChange={(v) => setValue('startDate', v ?? undefined)}
                          error={errors.startDate?.message}
                          enableFutureDates={true}
                          datePickerFontSize={7}
                          addClear
                        />
                      )}
                    />
                  </div>
                  <div className="w-full">
                    <Controller
                      control={control}
                      name="endDate"
                      render={({field: {value, onBlur, onChange}}) => (
                        <DatePicker
                          label="End Date"
                          placeholder="mm/dd/yyyy"
                          value={value}
                          onChange={(v) => setValue('endDate', v ?? undefined)}
                          error={errors.endDate?.message}
                          enableFutureDates={true}
                          datePickerFontSize={7}
                          addClear
                        />
                      )}
                    />
                  </div>
                </div>
            </>
          )}

          <div className="pt-3 flex flex-row justify-between items-center">
            <div>
              <Typography
                label="Advance Filters "
                variant="f1"
                weight="semibold"
                color={colorTheme.darkFaded}
              />
              <Typography
                label="Enabling this allows the user to use advance filters."
                variant="f1"
                color={colorTheme.darkFaded}
              />
            </div>
            <div>
              <Toggle value={enableAdvancefilter} onToggle={onToggle}/>
            </div>
          </div>
          
        </div>
      </SyledContent>
      <div className="w-full flex flex-row space-x-2 pt-4">
        <Button
          label="Cancel"
          variant="outlined"
          className="flex-1"
          onClick={props.onCancel}
          disabled={postSubscription.isLoading}
        />
        <div className="spacer" />
        <Button
          label="Add"
          color="primary"
          className="flex-1"
          onClick={submitForm}
          isLoading={postSubscription.isLoading}
        />
      </div>
    </div>
  );
};

export default CreateSubscriptionModalView;
