import React, {useEffect, useState} from 'react';
import Typography from '../../../core/components/typography';
import {colorTheme} from '../../../core/configs';
import {
  RadioButton,
  RadioButtonFilled,
} from '../../../core/assets/icons/components';
import {StyledEmploymentComponent} from './styled';
import {Nullable} from '../../types/types';
import {LivingArrangement} from '../../server/types/filter-settings.types';
import {localization} from '../../localization/Localization';

interface EmploymentComponentProps {
  value: LivingArrangement;
  onChange?: (value: LivingArrangement) => void;
}

const EmploymentComponent = (props: EmploymentComponentProps) => {
  const [selectedEmployment, setSelectedEmployment] = useState<LivingArrangement>(props.value);

  useEffect(() => {
    setSelectedEmployment(props.value);
  }, [props.value]);

  const selectHandler = (value: LivingArrangement) => {
    setSelectedEmployment(value);

    if (props.onChange) {
      props.onChange(value);
    }
  };

  return (
    <StyledEmploymentComponent>
      <Typography
        className="pb-2"
        label={localization.lbl_living_arr}
        color={!selectedEmployment ? colorTheme.danger
          : colorTheme.darkFaded}
        variant="f1"
        weight="semibold"
      />

      <div className="space-y-4">
        <div
          className="cursor-pointer"
          style={{width: 'fit-content'}}
          onClick={() => selectHandler('stayIn')}>
          <div className="flex flex-row items-center">
            {selectedEmployment === 'stayIn' ? (
              <RadioButtonFilled
                className="radio-button"
                color={colorTheme.dark}
              />
            ) : (
              <RadioButton className="radio-button" color={colorTheme.dark} />
            )}
            <Typography
              label={localization.rad_stay_in}
              variant="f2"
              color={colorTheme.dark}
            />
          </div>
        </div>

        <div
          className="cursor-pointer"
          style={{width: 'fit-content'}}
          onClick={() => selectHandler('stayOut')}>
          <div className="flex flex-row items-center">
            {selectedEmployment === 'stayOut' ? (
              <RadioButtonFilled
                className="radio-button"
                color={colorTheme.dark}
              />
            ) : (
              <RadioButton className="radio-button" color={colorTheme.dark} />
            )}
            <Typography
              label={localization.rad_stay_out}
              variant="f2"
              color={colorTheme.dark}
            />
          </div>
        </div>

        {/* <div
          className="cursor-pointer"
          style={{width: 'fit-content'}}
          onClick={() => selectHandler(null)}>
          <div className="flex flex-row items-center">
            {!selectedEmployment ? (
              <RadioButtonFilled
                className="radio-button"
                color={colorTheme.dark}
              />
            ) : (
              <RadioButton className="radio-button" color={colorTheme.dark} />
            )}
            <Typography
              label={localization.rad_all}
              variant="f2"
              color={colorTheme.dark}
            />
          </div>
        </div> */}
      </div>
    </StyledEmploymentComponent>
  );
};

export default EmploymentComponent;
