import {Typography} from '../../../../../core/components';
import {colorTheme} from '../../../../../core/configs';
import { BeatLoader } from 'react-spinners';

interface ProcessingPaymentDialogProps {
  label?: string
}

const ProcessingPaymentDialogView = ({
  label
}: ProcessingPaymentDialogProps) => {
  return (
    <div className="flex flex-col items-center p-4 space-y-4">
      <BeatLoader color={colorTheme.primary}/>
      <div className="space-y-2">
        <Typography
          label={label ?? "Processing payment"}
          align="center"
          weight="semibold"
          variant="f3"
          color={colorTheme.dark}
        />
        <div className="flex justify-center">
          <Typography
            align="center"
            label="Please do not close or go to other pages."
            weight="normal"
            variant="f1"
            color={colorTheme.darkFaded}
          />
        </div>
      </div>
    </div>
  );
};

export default ProcessingPaymentDialogView;
