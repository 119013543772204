import { Checkbox, Typography, Input, Select } from '../../../core/components';
import { colorTheme } from '../../../core/configs';
import { StyledRoleSkillsDiv } from './styled';
import { KazamRole, kazamSkills } from '../../server/data/kazam-skills';
import { useEffect, useState } from 'react';
import {Control, Controller, UseFormSetValue} from 'react-hook-form';
import { localization } from '../../localization/Localization';
import { exAbroad } from '../../server/types';
import { RoleSkillSchema } from './AdvanceProfileSetup';

interface RoleSkillsProps { 
  index: number
  kazamRoleId: string
  roleValue: KazamRole
  roleName: string
  countries: string[]
  selected?: string[]
  control: Control<RoleSkillSchema, any>
  setValue: UseFormSetValue<RoleSkillSchema>
}

const RoleSkills = ({
  index,
  kazamRoleId,
  roleValue,
  roleName,
  countries,
  selected,
  control,
  setValue
}: RoleSkillsProps) => {

  const getRoleSkills = (): string[] => {
    return kazamSkills[roleValue];
  }

  var [skills, setSkills] = useState<string[]>(getRoleSkills())
  var [showCountrySelection, setShowCountrySelection] = useState<boolean>(false)
  const [selectedSkills, setSelectedSkills] = useState<string[]>(selected ?? []);

  useEffect(() => {
    if(kazamRoleId) {
      setValue(`roleSkills.${index}.kazamRoleId`, kazamRoleId)
    }
  }, [kazamRoleId])

  const selectedHandler = (value: string) => {
    setSelectedSkills(prevState => {
      let newState: string[] = [];

      if (prevState) {
        if (!prevState.includes(value)) {
          // Add
          newState = [...prevState, value];
        } else if (prevState) {
          // Remove
          newState = prevState.filter(i => i !== value);
        }
      } else {
        // Add
        newState = [value];
      }

      setValue(`roleSkills.${index}.skills`, newState)
      
      return newState;
    });
  };

  return (
    <StyledRoleSkillsDiv className='flex flex-col'>
      <div className='space-y-5'>
        <div className="flex justify-center gap-2">
          <Typography
            label="ROLE: "
            variant="f3"
            align="center"
            weight='semibold'
            color={colorTheme.dark}
          />
          <Typography
            label={roleName}
            variant="f3"
            align="center"
            weight='normal'
            color={colorTheme.dark}
          />
        </div>
        <div className='roleDetails space-y-3 '>
          <div className="pt-1 flex gap-2 items-center">
            <div className=''>
              <Typography
                label="Experience:"
                variant="f3"
                weight='semibold'
                align='center'
                color={colorTheme.dark}
              />
            </div>
            <div className='exp'>
              <Controller
                control={control}
                key={kazamRoleId}
                name={`roleSkills.${index}.experience`}
                render={({ field: { value = '', onBlur, onChange }, fieldState }) => (
                  <Input
                    type="number"
                    key={kazamRoleId}
                    inputSize="large"
                    placeholder={localization.ph_years_experience}
                    value={
                      typeof value === 'number'
                        ? value
                        : isNaN(parseFloat(value as string))
                        ? ''
                        : parseFloat(value as string)
                    }
                    onBlur={onBlur}
                    onChange={onChange}
                    error={fieldState.error?.message}
                  />
                )} 
              />
            </div>
          </div>

          <div className="gap-3 flex items-center">
            <div className='flex items-center'>
              <Typography
                label="Ex Abroad?"
                variant="f3"
                weight='semibold'
                align='center'
                color={colorTheme.dark}
              />
            </div>
            <div className='exAbroad'>
              <Controller
                control={control}
                name={`roleSkills.${index}.exAbroad`}
                render={({ field: { value, onChange }, fieldState }) => (
                  <Select
                    placeholder={localization.ph_ex_abroad}
                    items={exAbroad}
                    itemRenderer={(item, selected) => (
                      <Typography
                        label={item}
                        variant="f1"
                        color={colorTheme.dark}
                        weight={selected ? 'semibold' : 'normal'}
                        className="flex-1"
                      />
                    )}
                    onChange={(v) =>{
                      onChange(v)
                      setShowCountrySelection(v == "Yes")
                    }}
                    value={value}
                    valueRenderer={value => value}
                    error={fieldState?.error?.message}
                  />
                )}
              />
            </div>
          </div>
          {showCountrySelection && (
            <div className="pt-1 space-y-2">
              <Typography
                label="Country of Experience"
                variant="f3"
                weight='semibold'
                color={colorTheme.dark}
              />
              <div className='country'>
                <Controller
                  control={control}
                  name={`roleSkills.${index}.countryOfExperience`}
                  render={({ field: { value, onChange }, fieldState }) => (
                    <Select
                      placeholder={localization.ph_country_experience}
                      items={countries}
                      itemRenderer={(item, selected) => (
                        <Typography
                          label={item}
                          variant="f1"
                          color={colorTheme.dark}
                          weight={selected ? 'semibold' : 'normal'}
                          className="flex-1"
                        />
                      )}
                      onChange={onChange}
                      value={value}
                      valueRenderer={value => value}
                      error={fieldState?.error?.message}
                    />
                  )}
                />
              </div>
            </div>
          )}
        </div>

        <Controller
          control={control}
          name={`roleSkills.${index}.skills`}
          render={({ field: { value, onChange }, fieldState }) => (
            <>
              <div className='flex flex-col gap-1 justify-center'>
                <div className="flex justify-center gap-1">
                  <Typography
                    label={roleName}
                    variant="f2"
                    align="center"
                    weight='normal'
                    color={colorTheme.dark}
                  />
                  <Typography
                    label=" Skills"
                    variant="f2"
                    align="center"
                    weight='normal'
                    color={colorTheme.dark}
                  />
                </div>

                {fieldState?.error?.message &&
                  <Typography
                    label={fieldState?.error?.message}
                    color={colorTheme.danger}
                    variant="f1"
                    weight="semibold"
                    align="center"
                  />
                }
              </div>

              <div className='ml-3 mr-2 '>
                <div className='flex flex-col skills'>
                  {skills.map((skill, i) => {
                    return (
                      <div
                        key={i}
                        className=''
                        style={{width: 'fit-content'}}
                        onClick={() => selectedHandler(skill)}>
                        <Checkbox
                          className="checkbox"
                          checked={selectedSkills.includes(skill)}
                          label={skill}
                        />
                      </div>
                    )
                  })}
                </div>
              </div>
            </>
          )}
        />
      </div>
    </StyledRoleSkillsDiv>
  );
};
export default RoleSkills;
