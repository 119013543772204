import styled from 'styled-components';
import {colorTheme} from '../../../../core/configs';
import { isIOS } from 'react-device-detect';

export const StyledDefaultProfileView = styled.div`
  position: relative;
  width: 100%;
  height: 340px;
  background: ${colorTheme.primary};
  border-radius: 16px;

  & .flex1 {
    flex: 1 1 0%;
    min-width: 0;
  }

  & .overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const StyledProfileOverlay = styled.div`
  padding: 16px;
  top: 0;
  position: absolute;
  width: 100%;
  height: 340px;
  background: rgba(0, 0, 0, 0.25);
  border-radius: 16px;
`;

export const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover !important;
  border-radius: 16px;
`;

export const StyledSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 4px;

  .label {
    text-decoration: underline;
  }

  .bordered {
    padding: 12px;
    border: 1px solid ${colorTheme.lightGray};
    border-radius: 8px;
    box-shadow: 3px 4px 4px 0px rgba(0, 0, 0, 0.2);
  }

  &.placeholder {
    padding: 0px;
  }
`;

export const StyledSeeMore = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  z-index: 1;
  right: 0;
  left: 0;
  bottom: ${isIOS ? '54px' : '0'};

  .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 42px;
    height: 42px;
    border-radius: 21px;
    background: ${colorTheme.light};
    box-shadow: 1px 4px 4px 0px rgba(0, 0, 0, 0.2);
  }
`;

