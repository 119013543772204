import {AnalyticsEvent} from '../../../app/hooks/useAnalytics';
import {RoutePath} from '../../../app/navigation/config/RouteConfig';
import {IconName} from '../icon';

export const homeownerPrimaryNavItems: NavItemList[] = [
  'chats',
  'favorites',
  'kaways',
  'hiringHistory',
  'notifications',
  'settings',
  'getVerified',
  'captureTrueSelfie',
  'takePersonalityTest'
];

export const kazamPrimaryNavItems: NavItemList[] = [
  'chats',
  'jobHistory',
  'notifications',
  'settings',
  'getVerified',
  'captureTrueSelfie',
  'takePersonalityTest',
  'advanceProfile'
];

export const adminPrimaryNavItems: NavItemList[] = [
  'home',
  'controls',
  'adminChats',
  'users',
  'ads',
  'referrals',
  'broadcast',
  'subscription',
  'voucher',
];

export const adminSecondaryNavItems: NavItemList[] = ['logout'];

export const secondaryNavItems: NavItemList[] = [
  'aboutUs',
  'latestNews',
  'termsAndConditions',
  'privacyPolicy',
  'logout',
];

export const unAuthenticatedPrimaryNavItems: NavItemList[] = ['settings'];

export const unAuthenticatedNavItems: NavItemList[] = [
  'homeLanding',
  'aboutUs',
  'latestNews',
  'termsAndConditions',
  'privacyPolicy',
];

export type NavItemList =
  | 'home'
  | 'homeLanding'
  | 'controls'
  | 'adminChats'
  | 'users'
  | 'ads'
  | 'referrals'
  | 'chats'
  | 'favorites'
  | 'kaways'
  | 'hiringHistory'
  | 'jobHistory'
  | 'notifications'
  | 'settings'
  | 'latestNews'
  | 'aboutUs'
  | 'termsAndConditions'
  | 'privacyPolicy'
  | 'broadcast'
  | 'logout'
  | 'subscription'
  | 'voucher'
  | 'getVerified'
  | 'captureTrueSelfie'
  | 'takePersonalityTest'
  | 'advanceProfile';

export type NavItem = {
  name: string;
  icon: IconName;
  path: RoutePath;
  disabled?: boolean;
  analyticsClickEventName?: AnalyticsEvent;
  url?: string;
};

export const navItems: {[key in NavItemList]: NavItem} = {
  home: {
    name: 'Home',
    icon: 'home',
    path: RoutePath.ADMIN_LANDING,
  },
  controls: {
    name: 'Controls',
    icon: 'sliders',
    path: RoutePath.ADMIN_CONTROLS,
  },
  adminChats: {
    name: 'Chats',
    icon: 'messageCircle',
    path: RoutePath.ADMIN_CHATS,
  },
  users: {
    name: 'Users',
    icon: 'users',
    path: RoutePath.ADMIN_USERS_ALL,
  },
  ads: {
    name: 'Ads',
    icon: 'flag',
    path: RoutePath.ADMIN_ADS,
  },
  referrals: {
    name: 'Referrals',
    icon: 'share',
    path: RoutePath.ADMIN_REFERRALS_BY_USER,
  },
  broadcast: {
    name: 'Broadcast',
    icon: 'send',
    path: RoutePath.ADMIN_BROADCAST,
  },
  chats: {
    name: 'sm_chat',
    icon: 'messageCircle',
    path: RoutePath.CHAT,
  },
  favorites: {
    name: 'sm_favorites',
    icon: 'heart',
    path: RoutePath.FAVORITES,
  },
  kaways: {
    name: 'sm_kaways',
    icon: 'wave',
    path: RoutePath.KAWAYS,
  },
  hiringHistory: {
    name: 'sm_hiring_history',
    icon: 'history',
    path: RoutePath.HISTORY,
  },
  jobHistory: {
    name: 'sm_job_history',
    icon: 'history',
    path: RoutePath.HISTORY,
  },
  notifications: {
    name: 'sm_notifications',
    icon: 'bell',
    path: RoutePath.NOTIFICATIONS,
  },
  settings: {
    name: 'sm_settings',
    icon: 'settings',
    path: RoutePath.SETTINGS,
  },
  latestNews: {
    name: 'sm_latest_news',
    icon: 'fileText',
    url: 'https://news.kazam.ph/',
    path: RoutePath.LATEST_NEWS,
  },
  homeLanding: {
    name: 'sm_home',
    icon: 'home',
    path: RoutePath.LANDING,
    analyticsClickEventName: 'non_user_clicked_home',
  },
  aboutUs: {
    name: 'sm_about_us',
    icon: 'alertCircle',
    path: RoutePath.ABOUT_US,
    analyticsClickEventName: 'non_user_clicked_about_us',
  },
  termsAndConditions: {
    name: 'sm_t_and_c',
    icon: 'book',
    path: RoutePath.TERMS_AND_CONDITIONS,
    analyticsClickEventName: 'non_user_clicked_terms_and_conditions',
  },
  privacyPolicy: {
    name: 'sm_data_privacy',
    icon: 'shield',
    path: RoutePath.PRIVACY_POLICY,
    analyticsClickEventName: 'non_user_clicked_data_privacy_policy',
  },
  logout: {
    name: 'sm_logout',
    icon: 'logOut',
    path: RoutePath.LOGOUT,
  },
  subscription: {
    name: 'Subscription',
    icon: 'creditCard',
    path: RoutePath.ADMIN_SUBSCRIPTION,
  },
  getVerified: {
    name: 'sm_get_verified',
    icon: 'checkCircle',
    path: RoutePath.GET_VERIFIED,
  },
  captureTrueSelfie: {
    name: 'sm_capture_true_selfie',
    icon: 'trueSelfie',
    path: RoutePath.GET_TRUE_SELFIE,
  },
  voucher: {
    name: 'Vouchers',
    icon: 'voucher',
    path: RoutePath.ADMIN_VOUCHER,
  },
  takePersonalityTest: {
    name: 'sm_take_personality_test',
    icon: 'brain',
    path: RoutePath.TAKE_PERSONALITY_TEST,
  },
  advanceProfile: {
    name: 'sm_advance_profile',
    icon: 'fileText',
    path: RoutePath.ADVANCE_PROFILE_LANDING,
  },
};
