export type KazamRoles = 'maid' | 'nanny' | 'offline';

export enum KazamRole {
    ChildCare = 0,
    SeniorCare = 1,
    Cook = 2,
    Maid = 3,
    FamilyDriver = 4,
    Houseboy = 5,
  }

export const kazamSkills: {[key in KazamRole]: string[]} = {
    [KazamRole.ChildCare]: [
        'Care of Newborns (0-1 year old)',
        'Care of Toddlers (2-3 years old)',
        'Care of Children (4-12 years old)',
        'Care for Special Needs Children',
        'Child-Related Chores',
        'Basic Tutoring',
        'Basic Housekeeping',
        'Preparing Meals for Children',
        'Basic First Aid'
    ],
    [KazamRole.SeniorCare]: [
        'Bedridden Seniors',
        'Mobile Seniors',
        'Elder With Mental Health Conditions',
        'Assisted Feeding (NGT, PEG)',
        'Elder Meal Preparations',
        'Supervision of Medication​',
        'Companion (Kasama)',
        'Basic Housekeeping',
        'Basic First Aid'
    ],
    [KazamRole.Cook]: [
        'Basic Cooking',
        'Advanced Cooking',
        'Restaurant Experience',
        'Canteen Experience',
        'Baking',
        'Dessert-making',
        'Grocery',
        'Wet Market (Palengke)',
        'Kitchen Cleaning and Maintenance',
        'Meal Planning',
        'Experience with Special Diets',
        'Table Setting'
    ],
    [KazamRole.Maid]: [
        'House Cleaning (Indoor)',
        'House Cleaning (Outdoor)',
        'Laundry (Handwash)',
        'Laundry (Machine Wash)',
        'Ironing and Folding',
        'Dishwashing',
        'Basic Cooking',
        'Pet Care'
    ],
    [KazamRole.FamilyDriver]: [
        'Manual Transmission',
        'Automatic Transmission',
        'Driving - Sedan',
        'Driving - SUV/Van',
        'Night Driving',
        'Car Cleaning',
        'Vehicle Maintenance',
        'Basic Home Maintenance',
        'Personal Errands',
        'Car Registration'
    ],
    [KazamRole.Houseboy]: [
        'Housekeeping',
        'Gardening',
        'Pet Care',
        'House Maintenance',
        'Car Cleaning',
        'Personal Errands',
        'Basic Plumbing',
        'Basic Electrical',
        'Basic Carpentry',
        'Pool Maintenance'
    ]
}

var data = kazamSkills[KazamRole.ChildCare]