import {
  Button,
  Typography,
} from '../../../core/components';
import {colorTheme} from '../../../core/configs';
import {useHistory, useLocation} from 'react-router-dom';
import {RoutePath} from '../../navigation/config/RouteConfig';
import Page from '../../../core/components/page/Page';
import logo from '../../../assets/KAZAM_logo_Transparent.svg';
import { Action } from 'history';
import { useState } from 'react';
import _ from 'lodash';
import { isMobile } from 'react-device-detect';
import { localization } from '../../localization/Localization';

const AdvanceProfileLanding = () => {
  const history = useHistory();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const [signUp] = useState<string>(params.get('sign-up') || '');
  
  const onCancel = () => {
    if (signUp) {
      const path = RoutePath.TAKE_PERSONALITY_TEST + '?sign-up=true';
      history.replace(path);
    } else {
      if (history.action === Action.Push) {
        history.go(-1);
      } else {
        history.replace('/');
      }
    }
  }

  const onProceed = () => {
    if (signUp) {
      history.replace(RoutePath.ADVANCE_PROFILE_SETUP + '?sign-up=true');
    } else {
      history.replace(RoutePath.ADVANCE_PROFILE_SETUP);
    }
  }

  return (
    <Page hideNavbar>
      <div className="space-y-6">
        <div className="flex justify-center">
          <img style={{height: '29px'}} src={logo} alt="Logo" />
        </div>

        <div className='flex flex-col lg:justify-between'
          style={{
            minHeight: isMobile
              ? '100%'
              : '500px'
          }}>
          <div>
            <div className="flex flex-1 justify-center">
              <Typography
                label={localization.advance_profile_header}
                variant="f3"
                align="center"
                weight='semibold'
                color={colorTheme.dark}
              />
            </div>
            <div className="pt-1">
              <Typography
                label={localization.advance_profile_desc}
                variant="f2"
                align="center"
                color={colorTheme.darkFaded}
              />
            </div>
          </div>
          <div className='fixed bottom-6 left-6 right-6 lg:relative lg:w-full lg:left-0 lg:bottom-0'>
            <div>
              <div>
                <Button
                  label={signUp ? "Setup Advanced Profile" : "Update Advanced Profile"}
                  color="primary"
                  onClick={onProceed}
                />
              </div>
              <div className="pt-4">
                <Button
                  label={signUp ? "Maybe Later" : "Cancel"}
                  variant="outlined"
                  onClick={onCancel}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};
export default AdvanceProfileLanding;
