import * as yup from 'yup';
import {calculateAge} from '../utils/date.util';

export const editKazamProfileSchema = yup.object({
  mobileNumber: yup.string().trim().required('This field is required.'),
  firstName: yup
    .string()
    .required('This field is required.')
    .matches(/^[ñÑa-zA-Z\s]+$/, 'First name must only have letters or ñ.'),
  lastName: yup
    .string()
    .required('This field is required.')
    .matches(/^[ñÑa-zA-Z\s]+$/, 'Last name must only have letters or ñ.'),
  birthDate: yup
    .date()
    .required()
    .typeError('Enter a valid date')
    .test('DOB', 'Must be 18 years old and above.', value => {
      return calculateAge(value!) >= 18;
    }),
  gender: yup.string().required('This field is required.'),
  civilStatus: yup.string().required('This field is required.'),
  withChildren: yup.string().required('This field is required.'),
  languages: yup.array()
    .required('This field is required.')
    .min(1, 'Select at least one.')
    .max(3, 'You can only select up to 3 items'),
  email: yup.string().email('Enter valid email address'),
  minSalary: yup
    .number()
    .required('This field is required.')
    .typeError('This field is required.'),
  locationId: yup.string().required('This field is required.'),
  livingArrangement: yup.string().required('This field is required.'),
  roles: yup.array().required('This field is required.'),
});

export const editHomeownerProfileSchema = yup.object({
  mobileNumber: yup.string().trim().required('This field is required.'),
  firstName: yup
    .string()
    .required('This field is required.')
    .matches(/^[ñÑa-zA-Z\s]+$/, 'First name must only have letters or ñ.'),
  lastName: yup
    .string()
    .required('This field is required.')
    .matches(/^[ñÑa-zA-Z\s]+$/, 'Last name must only have letters or ñ.'),
  birthDate: yup
    .date()
    .required()
    .typeError('Enter a valid date')
    .test('DOB', 'Must be 18 years old and above.', value => {
      return calculateAge(value!) >= 18;
    }),
  gender: yup.string().required('This field is required.'),
  civilStatus: yup.string().nullable(),
  withChildren: yup.string().nullable(),
  languages: yup.array()
    .optional()
    .max(3, 'You can only select up to 3 items'),
  email: yup.string().required('This field is required.').email('Enter valid email address'),
  locationId: yup.string().required('This field is required.'),
});

export const changePasswordSchema = yup.object({
      currentPassword: yup
      .string()
      .trim()
      .required('This field is required.')
      .min(6, 'Min of 6 characters.'),
      password: yup
      .string()
      .required('This field is required.')
      .min(6, 'Min of 6 characters.')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])/,
        "Must be a combination of uppercase and lowercase letters."
      )
      .matches(
        /^(?=.*[0-9])/,
        "Must contain at least one number."
      )
      .matches(
        /^(?=.*[.!@#\$%\^&\*])/,
        "Must contain at least one of these special characters (.!@#$%^&*)."
      )
      .notOneOf([yup.ref('currentPassword'), null], 'New password must be different from current password.'),
      repeatPassword: yup
      .string()
      .trim()
      .required('This field is required.')
      .min(6, 'Min of 6 characters.')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])/,
        "Must be a combination of uppercase and lowercase letters."
      )
      .matches(
        /^(?=.*[0-9])/,
        "Must contain at least one number."
      )
      .matches(
        /^(?=.*[.!@#\$%\^&\*])/,
        "Must contain at least one of these special characters (.!@#$%^&*)."
      )
      .oneOf([yup.ref('password'), null], 'Passwords do not match.'),
});

export const advanceProfileSchema = yup.object({

});