import React, {useEffect, useState} from 'react';
import Page from '../../../core/components/page/Page';
import Typography from '../../../core/components/typography';
import {colorTheme} from '../../../core/configs';
import {StyledInputLabel, StyledPageWrapper} from './styled';
import {Checkbox, Input} from '../../../core/components';
import EmploymentComponent from './EmploymentComponent';
import SearchAreaComponent from './SearchAreaComponent';
import RoleComponent from './RoleComponent';
import {Nullable} from '../../types/types';
import {
  FilterSettingBody,
  FilterSettingResponse,
  KazamException,
  KazamRole,
  LocationResponse,
  ProvinceResponse,
  Role,
} from '../../server/types';
import {
  LivingArrangement,
  SearchAreaType,
} from '../../server/types/filter-settings.types';
import {useHistory} from 'react-router-dom';
import {
  getProvinces,
  getRoles,
  useGetAdminControl,
  useGetFinderSetting,
  usePutFilterSetting,
} from '../../server/react-query';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {editFilterSettingSchema} from '../../schema/filter-setting.schema';
import {Controller} from 'react-hook-form';
import {RoutePath} from '../../navigation/config/RouteConfig';
import {useAuth} from '../../hooks/useAuth';
import {useQuery} from 'react-query';
import {localization} from '../../localization/Localization';
import {isMobile} from 'react-device-detect';
import { useToast } from '../../../core/components/toast';

const FinderSettingsPage = () => {
  const {presentToast} = useToast();
  
  const [selectedRoleIds, setSelectedRoleIds] = useState<string[]>([]);
  const [livingArrangement, setLivingArrangement] = useState<LivingArrangement>('stayIn');
  const [searchAreaType, setSearchAreaType] = useState<SearchAreaType>();
  const [selectedLocation, setSelectedLocation] = useState<LocationResponse>();
  const [selectedProvince, setSelectedProvince] = useState<ProvinceResponse>();
  const [nearbyRadius, setNearbyRadius] = useState<number>();
  const [provinces, setProvinces] = useState<ProvinceResponse[]>([]);
  const [roles, setRoles] = useState<Role[]>([]);
  const [fetchRoles, setFetchRoles] = useState(false);
  const [fetchProvinces, setFetchProvinces] = useState(false);
  const [nearbyError, setNearbyError] = useState('');
  const [provinceError, setProvinceError] = useState('');

  const {authData, updateAuthData} = useAuth();

  const history = useHistory();
  const {data: finderSettingData, isFetching} = useGetFinderSetting();

  const {data: enableLgbtqia} = useGetAdminControl('enableLgbtqia');

  const {data: rolesData, isFetching: isRolesFetching} = useQuery(
    ['get-roles'],
    getRoles,
    {
      enabled: fetchRoles,
    },
  );

  const {data: provincesData, isFetching: isProvinceFetching} = useQuery(
    ['get-provinces'],
    getProvinces,
    {
      enabled: fetchProvinces,
    },
  );

  const {
    control,
    getValues,
    setValue,
    handleSubmit,
    setError,
    formState: {errors},
  } = useForm<FilterSettingBody>({
    resolver: yupResolver(editFilterSettingSchema),
    defaultValues: {
      salary: 1,
      livingArrangement: 'stayIn',
      roles: [],
      location: '',
      province: '',
      nearbyRadius: 0,
      lgbtqia: true
    },
  });

  useEffect(() => {
    var provinces = localStorage.getItem('provinces');
    if (provinces) {
      var provincesData = JSON.parse(provinces) as ProvinceResponse[];
      setProvinces(provincesData);
    } else {
      setFetchProvinces(true);
    }

    var roles = localStorage.getItem('roles');
    if (roles) {
      var rolesData = JSON.parse(roles) as Role[];
      setRoles(rolesData);
    } else {
      setFetchRoles(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (provincesData) {
      setProvinces(provincesData);
      localStorage.setItem('provinces', JSON.stringify(provincesData));
    }
  }, [provincesData]);

  useEffect(() => {
    if (rolesData) {
      setRoles(rolesData);
      localStorage.setItem('roles', JSON.stringify(rolesData));
    }
  }, [rolesData]);

  useEffect(() => {
    if (finderSettingData) {
      const currentRoles = finderSettingData.kazamRoles.map(
        kazamRole => kazamRole.role.roleId,
      );
      setSelectedRoleIds(currentRoles);
      setLivingArrangement(finderSettingData.livingArrangement);
      setSearchAreaType(finderSettingData.searchAreaType);
      setSelectedLocation(finderSettingData.location);

      setValue('roles', currentRoles);
      setValue('searchAreaType', finderSettingData.searchAreaType);
      setValue('livingArrangement', finderSettingData.livingArrangement);
      setValue('salary', finderSettingData.salary);
      setValue('location', finderSettingData.location?.locationId);
      setValue('lgbtqia', finderSettingData.lgbtqia);

      if (finderSettingData.province) {
        setSelectedProvince(finderSettingData.province);
        setValue('province', finderSettingData.province.provinceId);
      }

      if (finderSettingData.nearbyRadius) {
        setNearbyRadius(finderSettingData.nearbyRadius);
        setValue('nearbyRadius', finderSettingData.nearbyRadius);
      }
    }
  }, [finderSettingData, setValue]);

  const selectedRoleHandler = (value: string[]) => {
    setValue('roles', value);
  };

  const selectedEmploymentHandler = (value: LivingArrangement) => {
    setValue('livingArrangement', value);
  };

  const selectedSearchAreaHandler = (value: SearchAreaType) => {
    setValue('searchAreaType', value);
    setSearchAreaType(value);
  };

  const putFilterSetting = usePutFilterSetting(getValues());

  const handleRoleError = (): boolean => {
    const roles = getValues().roles || [];
    return roles.length > 0 && roles.length <= 3;
  };

  const handleSearchAreaError = (): boolean => {
    const data = getValues();

    switch (searchAreaType) {
      case 'inYourCity':
        return true;
      case 'allMatches':
        return true;
      case 'nearby':
        var error = data.nearbyRadius ? '' : 'This field is required.';
        setNearbyError(error);
        return error ? false : true;
      case 'province':
        var error = data.province ? '' : 'This field is required.';
        setProvinceError(error);
        return error ? false : true;
      default:
        return true;
    }
  };

  const submitForm = () => {
    handleRoleError() && handleSearchAreaError() && handleSubmit(handleSave)();
  };

  const handleSave = async () => {
    try {
      await putFilterSetting.mutateAsync();

      // Update auth data
      if (authData?.user?.filterSetting) {
        const formValues: FilterSettingBody = getValues();

        const fsCopy: FilterSettingResponse = {
          ...authData.user.filterSetting,
        };
        fsCopy.locationId = formValues.location as string;
        fsCopy.salary = formValues.salary as number;
        fsCopy.livingArrangement = formValues.livingArrangement as LivingArrangement;

        fsCopy.kazamRoles = formValues.roles.map(roleId => {
          const role = roles.find(r => r.roleId === roleId) as Role;
          return {
            kazamRoleId: '',
            role: {...role},
          } as KazamRole;
        });

        authData.user.filterSetting = fsCopy;
        updateAuthData(authData);

        // clear stored match
        localStorage.removeItem('currentSessionTimeStamp');
        localStorage.removeItem('currentMatchesData');
        localStorage.removeItem('currentProfile');
      }

      history.replace(RoutePath.MATCHES_LANDING);
    } catch (e: any) {
      const exception = e.data as KazamException;

      if (exception) {
        if (e.status
          && e.status === 403
          && exception.errorCode === 'SalaryLimitError') {
            setError("salary", {message: exception.error});
        }

        presentToast({
          message: exception.error,
          variant: 'error',
          position: 'bottom',
        });
      } else {
        presentToast({
          message: "Failed to save.",
          variant: 'error',
          position: 'bottom',
        });
      }
    }
  };

  const onSelectProvince = (provinceId: string) => {
    setValue('province', provinceId);
    if (provinceId) {
      setProvinceError('');
    }
  };

  const onNearbyKmChange = (radius: number) => {
    setValue('nearbyRadius', radius);
    setNearbyRadius(radius);
    if (radius) {
      setNearbyError('');
    }
  };

  const onSalaryChange = (value: any) => {
    var data = value.currentTarget.value.replace(/,/g, '');
    setValue('salary', parseFloat(data));
  };

  return (
    <Page
      showLoading={isFetching}
      showBackButton
      showDesktopBackButton
      showDesktopSaveButton={!isMobile}
      showRightText={isMobile}
      rightText="Save"
      onRightActionClick={submitForm}
      rightActionIsLoading={putFilterSetting.isLoading}>
      <StyledPageWrapper>
        <Typography
          label={localization.hdr_search_settings}
          variant="f3"
          weight="semibold"
          align="center"
          color={colorTheme.dark}
        />

        <div className="section">
          <RoleComponent
            value={selectedRoleIds}
            onChange={selectedRoleHandler}
          />
        </div>

        <div className="section">
          <EmploymentComponent
            value={livingArrangement}
            onChange={selectedEmploymentHandler}
          />
        </div>

        <div className="section">
          <div className="space-y-1 pb-2">
            <StyledInputLabel data-testid="label-element">
              {localization.lbl_max_salary}
            </StyledInputLabel>
            {/* <Typography
              label={localization.desc_salary_homeo}
              variant="f1"
              align="left"
              color={colorTheme.danger}
            /> */}
          </div>

          <div>
            <Controller
              control={control}
              name="salary"
              render={({field: {value = '', onBlur, onChange}}) => (
                <Input
                  placeholder={localization.ph_enter_max_salary}
                  onChange={onSalaryChange}
                  type="number"
                  inputSize="large"
                  onBlur={onBlur}
                  error={errors.salary?.message}
                  value={
                    typeof value === 'number'
                      ? value
                      : isNaN(parseFloat(value as string))
                      ? ''
                      : parseFloat(value as string)
                  }
                  showThousandSeparator
                />
              )}
            />
          </div>
        </div>

        <div className="section">
          <SearchAreaComponent
            provincesData={provinces}
            value={searchAreaType}
            onChange={selectedSearchAreaHandler}
            currentLocationName={selectedLocation?.text}
            onSelectProvince={onSelectProvince}
            onNearbyChange={onNearbyKmChange}
            selectedProvince={selectedProvince}
            currentNearbyKm={nearbyRadius}
            provinceError={provinceError}
            nearbyError={nearbyError}
          />
        </div>
        
        {enableLgbtqia && enableLgbtqia.enabled &&       
          <div className='flex pt-4'>
            <Controller
              control={control}
              name="lgbtqia"
              render={({field: {value = false, onBlur, onChange}}) => (
                <div className="flex">
                  <Checkbox
                    checked={finderSettingData?.lgbtqia}
                    onChange={onChange}
                    color={'default'}
                  />
                  <div className="flex space-x-1">
                    <Typography
                      label={localization.check_include_lgbtqia}
                      variant="f2"
                      align="left"
                      color={colorTheme.darkFaded}></Typography>
                  </div>
                </div>
              )}
            />
          </div>
        }
      </StyledPageWrapper>
    </Page>
  );
};

export default FinderSettingsPage;
