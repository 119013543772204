import {FilterSettingResponse} from '.';
import {Nullable} from '../../types/types';
import {LivingArrangement} from './filter-settings.types';
import { JobHistoryResponse } from './job-offer.types';

export type UserRole = 'homeowner' | 'kazam' | 'admin';

export type UserStatus = 'active' | 'banned' | 'disabled' | 'deleted' | 'suspended';

export type Gender = 'male' | 'female';

export type CivilStatus = 'single' | 'married' | 'divorced' | 'separated' | 'widowed';

export const genderList = ['Male', 'Female'];

export const languageList = ['English', 'Taglish'];

export const civilStatusList = ['Single', 'Married', 'Divorced', 'Separated', 'Widowed'];

export const withChildren = ['Yes', 'No'];

export const languageDialectList = ['Tagalog', 'English', 'Bisaya', 'Ilocano', 'Ilonggo', 'Bicolano', 'Waray',
  'Kapampangan', 'Pangasinense', 'Aklanon', 'Tausūg', 'Chavacano', 'Surigaonon', 'Ivatan', 'Ibanag', 'Yakan',
  'Kinaray-a', 'Maguindanao', 'Maranao', 'Sambal', 'Fookien (Hokkien)', 'Cantonese', 'Mandarin Chinese', 'Bengali', 'Arabic',
  'Hausa', 'Hindi', 'Indonesian', 'Iranian Persian (Farsi)', 'Italian', 'Japanese', 'Javanese', 'Kannada','Marathi',
  'Nigerian Pidgin', 'Portuguese','Russian', 'Spanish', 'Swahili', 'Tamil', 'Telugu', 'Thai', 'Urdu', 'Vietnamese', 'Western Punjabi'
];

export const exAbroad = ['Yes','No']

export type VerifiedStatus = 'notVerified' | 'pending' | 'verified' | 'rejected';

export interface LoginInformation {
  mobileNumber: string;
  password: string;
  repeatPassword: string;
  acceptTerms: boolean;
  acceptDataPrivacy: boolean;
  consentMarketing?: boolean;
}

export interface OTP {
  code: string;
}

export interface UploadPhotoReqBody {
  image?: any | null;
}

export interface UploadMediaReqBody {
  file?: any | null;
  channelId: string;
}

export interface PrimaryInformation {
  firstName: string;
  lastName: string;
  birthDate: Date;
  gender: string;
  civilStatus: string;
  withChildren: string;
  languages: string[];
  location: string;
  lgbtqia: boolean;
}

export interface PostUserBody {
  mobileNumber: string;
  firebaseUserId: string;
  firebaseRefreshToken: string;
  userRole: UserRole;
  firstName: string;
  lastName: string;
  birthDate: Date;
  gender: string;
  email?: string;
  password: string;
  referralCode?: string;
  civilStatus?: string;
  withChildren?: string;
  languages?: string[];
}

export interface UserResponse {
  userId: string;
  firebaseUserId: string;
  FirebaseCMToken?: string;
  firebaseRefreshToken: string;
  userRole: UserRole;
  userStatus: UserStatus;
  mobileNumber: string;
  firstName: string;
  lastName: string;
  birthDate: Date;
  gender: string;
  email?: string;
  photo: string;
  createdOn: Date;
  receiveMarketingEmails: boolean;
  modifiedOn: Date;
  filterSetting: FilterSettingResponse;
  isBanned: boolean;
  referralCode: string;
  lastAcceptedTerms?: Date;
  lastAcceptedPrivacyPolicy?: Date;
  lastAcceptedChatDisclaimer?: Date;
  isEligibleForTrial: boolean;
  verifiedStatus: VerifiedStatus;
  jobHistory?: JobHistoryResponse[];
  personalityMatch?: PersonalityMatch;
  personalityMatchScore?: number;
  faceDocumentVerified?: boolean;
  trueSelfieVerified?: boolean;
  lastVerifiedDate?: Date;
  lastTrueSelfieVerifiedDate?: Date;
  lastRevokedVerificationId?: string;
  civilStatus?: string;
  withChildren?: string;
  languages?: string[];
}

export interface GetSystemStatisticsResponse {
  allUsers: number;
  activeUsers: number;
  inactiveUsers: number;
  kazamUsers: number;
  homeownerUsers: number;
  activeKazams: number;
  activeHomeowners: number;
  inactiveKazams: number;
  inactiveHomeowners: number;
}

export interface PutUserProfileBody {
  mobileNumber: string;
  firstName: string;
  lastName: string;
  birthDate: Date;
  gender: string;
  email?: string;
  locationId: string;
  minSalary?: number;
  livingArrangement?: LivingArrangement;
  roles?: string[];
  civilStatus?: string;
  withChildren?: boolean;
  languages?: string[];
  lgbtqia?: boolean
}

export interface PatchUserProfileBody {
  email?: string;
  receiveSearchMatchEmailNotifs: boolean;
  receiveMarketingEmails: boolean;
}

export interface PatchUserProfileFCMTokenBody {
  token: string;
}

export interface PatchUserProfileStatusBody {
  status: UserStatus;
  reason: string;
}

export interface PostIsMobileExisting {
  mobileNumber: string;
}

export interface PostVerifyOtp {
  mobileNumber: string;
  otp: string;
}

export interface PostPasswordResetRequest {
  mobileNumber: string;
}

export interface PutPasswordResetRequest {
  mobileNumber: string;
  password: string;
}

export interface ResetPassword {
  password: string;
  repeatPassword: string;
}

export interface ChangePassword {
  currentPassword: string;
  password: string;
  repeatPassword: string;
}

export interface ChangePasswordBody {
  currentPassword: string;
  newPassword: string;
}


export interface Experience {
    title: string;
    date: Date;
}

export interface ExperienceResponse {
  page: number;
  data: Experience[];
  tier1LastIndex?: number;
}


export interface PatchUserLocationBody {
  locationId: string
}

export interface PersonalityMatch {
  personalityMatchId: string
  answers: string
  createdOn: Date
}
