import { Typography } from "../../../../core/components";
import Pill from "../../../../core/components/pill";
import { colorTheme } from "../../../../core/configs";
import { UserResponse } from "../../../server/types";
import PlaceholderSection from "./PlaceholderSection";
import { StyledSection } from "./styles";


export interface OtherInfoSectionProps {
  profile?: UserResponse
  showExtendedProfile?: boolean
}

const OtherInfoSection = ({
  profile,
  showExtendedProfile
}: OtherInfoSectionProps) => {

  const getCivilStatusDesc = (): string => {
    var desc = `${profile?.civilStatus ?? "No data"}`
    if (profile?.withChildren){
      desc = desc + ` with children`
    }

    return desc;
  }

  if (profile?.languages?.length === 0) return <></>;

  if (!showExtendedProfile) return <PlaceholderSection label="Other Information" />;

  return (
    <StyledSection >
      {/* <Typography
        className='label'
        label="Other Information"
        variant="f1"
        weight="semibold"
        color={colorTheme.darkFaded}
      /> */}

      <div className="bordered flex flex-col gap-2">
        {/* <Typography
          className='label'
          label="Other Info"
          variant="f1"
          weight="semibold"
          color={colorTheme.darkFaded}
        /> */}

        <div className="flex gap-2">
          <Typography
            label="Civil Status:"
            variant="f1"
            weight="semibold"
            color={colorTheme.darkFaded}
          />

          <Typography
            label={getCivilStatusDesc()}
            variant="f1"
            color={colorTheme.darkFaded}
          />
        </div>

        <div className="flex gap-2">
          <Typography
            label="Spoken Dialects:"
            variant="f1"
            weight="semibold"
            color={colorTheme.darkFaded}
          />

          {profile?.languages?.length == 0 &&
            <Typography
              label="No data"
              variant="f1"
              color={colorTheme.darkFaded}
            />
          }
        </div>

        <div className='flex gap-2 flex-wrap'>
          {profile?.languages?.map((l,i) => {
            return (
              <Pill
                key={i}
                value={l}
              />
            )
          })}
        </div>
        </div>
    </StyledSection>
  );
};

export default OtherInfoSection;
