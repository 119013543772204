import styled from "styled-components";
import { colorTheme } from "../../configs";

export const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: fit-content;
  padding: 4px 12px 4px 12px;
  border-radius: 30px;
  border: 1px solid ${colorTheme.accent};
`;
