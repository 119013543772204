import { Icon, Typography } from "../../../../core/components";
import { useDialog } from "../../../../core/components/dialog";
import Pill from "../../../../core/components/pill";
import { colorTheme } from "../../../../core/configs";
import { KazamRole, UserResponse } from "../../../server/types";
import GenericConfirmationDialog from "../../dialog-content/generic-dialog/GenericConfirmationDialog";
import PlaceholderSection from "./PlaceholderSection";
import { StyledSection } from "./styles";


export interface RoleSkillSectionProps {
  profile?: UserResponse
  showExtendedProfile?: boolean
}

const RoleSkillSection = ({
  profile,
  showExtendedProfile
}: RoleSkillSectionProps) => {
  const {presentDialog} = useDialog();
  
  const hasSkillsData = profile?.filterSetting?.kazamRoles
    ?.some(kr => kr.skills.length > 0);

  const getExperienceDesc = (kr: KazamRole, forPopup?: boolean): string => {
    var experience = `${forPopup ? `<span class='bold'>` : ''}${kr.experience}${forPopup ? `</span>` : ''} ${kr.experience > 1 ? 'years' : 'year'}
      ${(kr.exAbroad && !forPopup) ? 'overseas' : ''} experience as ${forPopup ? `<span class='bold'>` : ''}${kr.role.text}${forPopup ? `</span>` : ''}`

    if (kr.exAbroad){
      experience = experience + ` in ${forPopup ? `<span class='bold'>` : ''}${kr.countryOfExperience}${forPopup ? `</span>` : ''}`
    }

    return experience;
  }

  const getAllOverseasExperiences = (): string => {
    var desc = "This user has an overseas work experience.<br/><br/>"
    var experiences = profile?.filterSetting?.kazamRoles
      .filter(kr => kr.exAbroad)
      .map(kr => { return getExperienceDesc(kr, true)});

    return `${desc} ${experiences?.join('<br/>') ?? ""}`;
  }

  const showAbroadExperiences = () => {
    presentDialog({
      headerText: '',
      content: (
      <GenericConfirmationDialog
          headerText="OVERSEAS WORK EXPERIENCE"
          desc={getAllOverseasExperiences()}
      />
      ),
      enableBackdropDismiss: true,
      hideClose: false
    }); 
  }

  if (!hasSkillsData) return <></>;

  if (!showExtendedProfile)
    return <PlaceholderSection
      label="Experience & Skills"
      numberOfItems={profile?.filterSetting?.kazamRoles.length ?? 3}
    />;

  return (
    <StyledSection >
      <Typography
        className='label'
        label="Experience & Skills"
        variant="f1"
        weight="semibold"
        color={colorTheme.darkFaded}
      />

      {profile?.filterSetting?.kazamRoles.map((kr, i) => {
        if (kr.skills.length == 0) return <></>;
        return (
          <div key={i} className="bordered flex flex-col gap-2">
            <div className="flex gap-2">
              <Typography
                label={kr.role.text}
                variant="f1"
                weight="semibold"
                color={colorTheme.darkFaded}
              />

              {kr.exAbroad &&
                <Icon
                  type="button"
                  name="airplane"
                  color={colorTheme.blue}
                  size={20}
                  onClick={showAbroadExperiences}
                />
              }
            </div>

            {!!kr.experience &&
              <Typography
                label={getExperienceDesc(kr)}
                variant="f1"
                color={colorTheme.darkFaded}
              />
            }
            
            {kr.skills.length > 0 &&
              <Typography
                label="Skills"
                variant="f1"
                weight="semibold"
                color={colorTheme.darkFaded}
              />
            }

            <div className='flex gap-2 flex-wrap'>
              {kr.skills?.map((p,i) => {
                return (
                  <Pill
                    key={i}
                    value={p}
                  />
                )
              })}
            </div>
          </div>
        );
      })}
    </StyledSection>
  );
};

export default RoleSkillSection;
