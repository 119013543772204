import {
  Button,
  Checkbox,
  Typography,
} from '../../../core/components';
import { colorTheme } from '../../../core/configs';
import { useHistory, useLocation } from 'react-router-dom';
import { RoutePath } from '../../navigation/config/RouteConfig';
import Page from '../../../core/components/page/Page';
import logo from '../../../assets/KAZAM_logo_Transparent.svg';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import { isMobile } from 'react-device-detect';
import { localization } from '../../localization/Localization';
import { useForm, Controller } from 'react-hook-form';
import { useGetFinderSetting, usePutRoleSkills } from '../../server/react-query';
import { useGetCountries, useGetPersonalities } from '../../server/react-query/useApiData';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { StyledRoleSkillsDiv } from './styled';
import { PutRoleSkills, RoleSkill } from '../../server/types/filter-settings.types';
import { useToast } from '../../../core/components/toast';
import RoleSkills from './RoleSkills';
import { Action } from 'history';

export interface RoleSkillSchema {
  personalities: string[]
  roleSkills: {
    kazamRoleId: string
    experience?: number
    exAbroad: string
    countryOfExperience?: string
    skills: string[]
  }[]
}
const roleSkillsSchema = yup.object().shape({
  roleSkills: yup.array().of(
    yup.object().shape({
      kazamRoleId: yup.string().required("This field is required"),
      experience: yup
        .number()
        .nullable()
        .transform((value, originalValue) => (originalValue === "" ? null : value))
        .when('exAbroad', {
          is: 'Yes',
          then: yup.number()
            .required('This field is required')
            .min(1, "Must be greater or equal to 1.")
            .typeError('This field is required.'),
          otherwise: yup.number()
            .nullable()
            .min(0, "Must be greater or equal to 0."),
        }),
      exAbroad: yup.string().required("This field is required"),
      skills: yup.array().required("Select at least one.").min(1, "Select at least one."),
      countryOfExperience: yup
        .string()
        .when('exAbroad', {
          is: 'Yes',
          then: yup.string().required('This field is required').typeError('This field is required.'),
          otherwise: yup.string().notRequired()
        }),
    }),
  ),
  personalities: yup.array().required("Select at least one.").min(1, "Select at least one."),
});

const AdvanceProfileSetup = () => {
  const history = useHistory();
  const location = useLocation();
  const {presentToast} = useToast();

  const params = new URLSearchParams(location.search);
  const [signUp] = useState<string>(params.get('sign-up') || '');

  const { data, isFetching } = useGetFinderSetting();
  const { data: countries, isFetching: isFetchingCountries } = useGetCountries();
  const { data: personalities, isFetching: isFetchingPersonalities } = useGetPersonalities();
  const putRoleSkills = usePutRoleSkills();

  const [selectedPersonalities, setSelectedPersonalities] = useState<string[]>([])

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    reset
  } = useForm<RoleSkillSchema>({
    resolver: yupResolver(roleSkillsSchema),
  });

  useEffect(() => {
    if (data) {
      var schema: RoleSkillSchema = {
        personalities: data?.personalities ?? [],
        roleSkills: []
      }

      var roleSkills = data.kazamRoles.map((r, i) => {
        return {
          kazamRoleId: r.kazamRoleId,
          experience: r.experience ?? null,
          exAbroad: r.exAbroad ? "Yes" : "No",
          countryOfExperience: r.countryOfExperience,
          skills: r.skills
        }
      })
      schema.roleSkills = roleSkills;
      reset(schema);
      setSelectedPersonalities(schema.personalities);
    }
  }, [data])

  const selectedHandler = (value: string) => {
    setSelectedPersonalities(prevState => {
      let newState: string[] = [];

      if (prevState) {
        if (!prevState.includes(value)) {
          // Add
          newState = [...prevState, value];
        } else if (prevState) {
          // Remove
          newState = prevState.filter(i => i !== value);
        }
      } else {
        // Add
        newState = [value];
      }

      setValue("personalities", newState)
      
      return newState;
    });
  };

  const onSave = () => {
    handleSubmit(saveRoleSkills)();
  }

  const saveRoleSkills = async () => {
    try {
      const data = getValues();
      var roleSkills = data.roleSkills.map((r, i) => {
        return {
          kazamRoleId: r.kazamRoleId,
          experience: r.experience,
          exAbroad: r.exAbroad === "Yes",
          countryOfExperience: r.countryOfExperience,
          skills: r.skills
        } as RoleSkill
      })
      var body: PutRoleSkills = {
        personalities: data.personalities,
        roleSkills
      }

      await putRoleSkills.mutateAsync(body)

      redirection()
    } catch (error) {
      presentToast({
        message: 'Failed to recover account.',
        variant: 'error',
        position: 'bottom',
      });
    }
  }

  const redirection = () => {
    if (signUp) {
      const path = RoutePath.TAKE_PERSONALITY_TEST + '?sign-up=true';
      history.replace(path);
    } else {
      if (history.action === Action.Push) {
        history.go(-1);
      } else {
        history.replace('/');
      }
    }
  }

  return (
    <Page hideNavbar>
      <div className="space-y-6">
        <div className="flex justify-center">
          <img style={{ height: '29px' }} src={logo} alt="Logo" />
        </div>
        <div className='space-y-4'>
          <div className='flex flex-col lg:justify-between space-y-3'
            style={{
              minHeight: isMobile
                ? '100%'
                : '500px'
            }}>
            <div>
              <div className="pt-1">
                <Typography
                  label={localization.advance_profile_page_header}
                  variant="f3"
                  align="center"
                  weight='semibold'
                  color={colorTheme.black}
                />
              </div>
            </div>

            <div className='flex flex-col space-y-4'>
              {data?.kazamRoles?.map((role, i) => {
                return (
                  <RoleSkills
                    control={control}
                    setValue={setValue}
                    index={i}
                    key={role.kazamRoleId}
                    roleName={role.role.text}
                    roleValue={role.role.value}
                    countries={countries ?? []}
                    kazamRoleId={role.kazamRoleId}
                    selected={role.skills}
                  />
                )
              })}
            </div>
            
            {!isFetching
              && !isFetchingCountries
              && !isFetchingPersonalities &&

              <StyledRoleSkillsDiv>
                <Controller
                  control={control}
                  name="personalities"
                  render={({ field: { value, onChange }, fieldState }) => (
                    <div className='flex flex-col space-y-4'>
                      <div className='flex flex-col gap-1 justify-center'>
                        <div className="flex justify-center gap-1">
                          <Typography
                            label="Personal Characteristics"
                            variant="f2"
                            align="center"
                            weight='normal'
                            color={colorTheme.dark}
                          />
                        </div>

                        {fieldState?.error?.message &&
                          <Typography
                            label={fieldState?.error?.message}
                            color={colorTheme.danger}
                            variant="f1"
                            weight="semibold"
                            align="center"
                          />
                        }
                      </div>

                      <div className='ml-3 mr-2 '>
                        <div className='flex flex-col skills'>
                          {personalities?.map((personality, i) => {
                            return (
                              <div
                                key={i}
                                className=''
                                style={{width: 'fit-content'}}
                                onClick={() => selectedHandler(personality)}>
                                <Checkbox
                                  className="checkbox"
                                  checked={selectedPersonalities.includes(personality)}
                                  label={personality}
                                />
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  )}
                />
              </StyledRoleSkillsDiv>
          }
          </div>
          <div>
            <div>
              <Button
                label="Save"
                isLoading={isFetching
                  || isFetchingCountries
                  || isFetchingPersonalities
                  || putRoleSkills.isLoading
                }
                color="primary"
                onClick={onSave}
              />
            </div>
            <div className="pt-4">
              <Button
                label={signUp ? "Maybe Later" : "Cancel"}
                variant="outlined"
                onClick={redirection}
              />
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};
export default AdvanceProfileSetup;
