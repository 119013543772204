import * as yup from 'yup';

import {
  LoginInformation,
  OTP,
  PostPasswordResetRequest,
  ResetPassword,
} from '../server/types/user.types';
import {calculateAge} from '../utils/date.util';

export const signUpLoginInformationSchema: yup.SchemaOf<LoginInformation> = yup.object(
  {
    mobileNumber: yup
      .string()
      .trim()
      .required('This field is required.')
      .length(11, 'Invalid mobile number format (e.g. 09xxxxxxxxx).')
      .matches(
        /^(09)\d{9}$/,
        'Invalid mobile number format (e.g. 09xxxxxxxxx).',
      ),
    password: yup
      .string()
      .required('This field is required.')
      .min(6, 'Min of 6 characters.')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])/,
        "Must be a combination of uppercase and lowercase letters."
      )
      .matches(
        /^(?=.*[0-9])/,
        "Must contain at least one number."
      )
      .matches(
        /^(?=.*[.!@#\$%\^&\*])/,
        "Must contain at least one of these special characters (.!@#$%^&*)."
      ),
    repeatPassword: yup
      .string()
      .trim()
      .required('This field is required.')
      .min(6, 'Min of 6 characters.')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])/,
        "Must be a combination of uppercase and lowercase letters."
      )
      .matches(
        /^(?=.*[0-9])/,
        "Must contain at least one number."
      )
      .matches(
        /^(?=.*[.!@#\$%\^&\*])/,
        "Must contain at least one of these special characters (.!@#$%^&*)."
      )
      .oneOf([yup.ref('password'), null], 'Passwords do not match.'),
    acceptTerms: yup
      .boolean()
      .required()
      .oneOf([true], 'The terms and conditions must be accepted.'),
    acceptDataPrivacy: yup
      .boolean()
      .required()
      .oneOf([true], 'The data privacy must be accepted.'),
    consentMarketing: yup.boolean(),
  },
);

export const signUpOTPSchema: yup.SchemaOf<OTP> = yup.object({
  code: yup
    .string()
    .required('This field is required.')
    .length(6, 'Must be 6 digits.'),
});

export const signUpEmployerPrimaryInformationSchema = yup.object({
  firstName: yup
    .string()
    .required('This field is required.')
    .matches(/^[ñÑa-zA-Z\s]+$/, 'First name must only have letters or ñ.'),
  lastName: yup
    .string()
    .required('This field is required.')
    .matches(/^[ñÑa-zA-Z\s]+$/, 'Last name must only have letters or ñ.'),
  birthDate: yup
    .date()
    .required()
    .typeError('Enter a valid date')
    .test('DOB', 'Must be 18 years old and above.', value => {
      return calculateAge(value!) >= 18;
    }),
  gender: yup.string().required('This field is required.'),
  civilStatus: yup.string().nullable(),
  withChildren: yup.string().nullable(),
  languages: yup.array()
    .optional()
    .max(3, 'You can only select up to 3 items'),
  location: yup.string().required('This field is required.'),
});

export const signUpPrimaryInformationSchema = yup.object({
  firstName: yup
    .string()
    .required('This field is required.')
    .matches(/^[ñÑa-zA-Z\s]+$/, 'First name must only have letters or ñ.'),
  lastName: yup
    .string()
    .required('This field is required.')
    .matches(/^[ñÑa-zA-Z\s]+$/, 'Last name must only have letters or ñ.'),
  birthDate: yup
    .date()
    .required()
    .typeError('Enter a valid date')
    .test('DOB', 'Must be 18 years old and above.', value => {
      return calculateAge(value!) >= 18;
    }),
  gender: yup.string().required('This field is required.'),
  civilStatus: yup.string().required('This field is required.'),
  withChildren: yup.string().required('This field is required.'),
  languages: yup.array()
    .required('This field is required.')
    .min(1, 'Select at least one.')
    .max(3, 'You can only select up to 3 items'),
  location: yup.string(),
});

export const signUpJobSeekerInformationSchema = yup.object({
  minSalary: yup
    .number()
    .required('This field is required.')
    .typeError('This field is required.'),
  location: yup.string().required('This field is required.'),
  livingArrangement: yup.string().required('This field is required.'),
});

export const signUpEmailNotifsSchema = yup.object({
  email: yup
    .string()
    .email('Enter valid email address')
    .required('This field is required.'),
});

export const passwordResetRequestSchema: yup.SchemaOf<PostPasswordResetRequest> = yup.object(
  {
    mobileNumber: yup
      .string()
      .trim()
      .required('This field is required.')
      .length(11, 'Invalid mobile number format (e.g. 09xxxxxxxxx).')
      .matches(
        /^(09)\d{9}$/,
        'Invalid mobile number format (e.g. 09xxxxxxxxx).',
      ),
  },
);

export const resetPassword: yup.SchemaOf<ResetPassword> = yup.object({
  password: yup
    .string()
    .required('This field is required.')
    .min(6, 'Min of 6 characters.')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])/,
      "Must be a combination of uppercase and lowercase letters."
    )
    .matches(
      /^(?=.*[0-9])/,
      "Must contain at least one number."
    )
    .matches(
      /^(?=.*[.!@#\$%\^&\*])/,
      "Must contain at least one of these special characters (.!@#$%^&*)."
    ),
  repeatPassword: yup
    .string()
    .trim()
    .required('This field is required.')
    .min(6, 'Min of 6 characters.')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])/,
      "Must be a combination of uppercase and lowercase letters."
    )
    .matches(
      /^(?=.*[0-9])/,
      "Must contain at least one number."
    )
    .matches(
      /^(?=.*[.!@#\$%\^&\*])/,
      "Must contain at least one of these special characters (.!@#$%^&*)."
    )
    .oneOf([yup.ref('password'), null], 'Passwords do not match.'),
});
